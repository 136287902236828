import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import * as H from "history";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    history: H.History;
    location: any;
    // Customizable Area End
}

// Customizable Area Start
export interface ValidResponseType {
    message: object;
    data: object;
}

export interface InvalidResponseType {
    errors: [
        "Record not found."
    ]
}

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
}

export interface AppointmentDataType {
    data: {
        id: string;
        type: string;
        attributes: {
            health_id: string;
            patient_phone_number: string;
            time_slot: string;
            end_time: string;
            mode_type: string;
            patient_full_name: string;
            patient_gender: string;
            patient_address: string;
            patient_weight: string;
            patient_blood_group: string;
            patient_year: string;
            patient_profile_image: string;
            symtoms: string;
            last_visit: string;
            patient_id: number;
        }
    }
}

export interface DoctorDetailsType {
    data: DoctorDetailsData;
}
export interface DoctorDetailsData {
    id: string,
    type: string,
    attributes: {
        full_name: string,
        medical_representative_name: string,
        representative_contact_no: string,
        profile_image: string
    }
}
// Customizable Area End

interface S {
    // Customizable Area Start
    deleteModal: boolean;
    appointmentId: number;
    appointmentData: AppointmentDataType;
    symptomsData: Array<string>;
    errorRecordFound: boolean;
    showPopup: boolean;
    doctorProfileData: object;
    patientId: number;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class AppointmentsDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAppointmentDetailsCallId: string = "";
    getProfileDetailsCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            deleteModal: false,
            appointmentData: {
                data: {
                    id: "",
                    type: "",
                    attributes: {
                        health_id: "",
                        patient_phone_number: "",
                        time_slot: "",
                        end_time: "",
                        mode_type: "",
                        patient_full_name: "",
                        patient_gender: "",
                        patient_address: "",
                        patient_weight: "",
                        patient_blood_group: "",
                        patient_year: "",
                        patient_profile_image: "",
                        symtoms: "",
                        last_visit: "",
                        patient_id: 0
                    }
                }
            },
            appointmentId: 0,
            symptomsData: [],
            errorRecordFound: false,
            showPopup: false,
            doctorProfileData: {},
            patientId: 0
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson.status === 500) {
                MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
                return;
            }
            if (this.isValidResponse(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            } else if (this.inValidResponse(responseJson)) {
                this.apiFailureCallBacks(apiRequestCallId, responseJson);
            } else if (errorReponse) {
                notification["error"]({
                    message: "Internal Server Error.",
                });
            }
        }
    }

    async componentDidMount() {
        this.setState({ appointmentId: this.props.location.state.appointmentId }, () => {
            this.getAppointmentDetailsfunction();
        });
        this.getProfileDetailsfunction();
    }

    apiCall = (data: APIPayloadType) => {
        let { contentType, method, endPoint, body } = data;
        let token = localStorage.getItem("token");
        const header = {
            "Content-Type": contentType,
            token
        };
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }

    isValidResponse = (responseJson: ValidResponseType) => {
        return (responseJson && responseJson.data);
    };

    inValidResponse = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };

    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: AppointmentDataType & DoctorDetailsType) => {
        if (apiRequestCallId === this.getAppointmentDetailsCallId) {
            this.getAppointmentDetailsSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getProfileDetailsCallId) {
            this.getProfileDetailsSuccessCallBack(responseJson);
        }
    };

    apiFailureCallBacks = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.getAppointmentDetailsCallId) {
            this.getAppointmentDetailsFailureCallBack(responseJson);
        }
    };

    getAppointmentDetailsfunction = async () => {
        this.getAppointmentDetailsCallId = this.apiCall({
            contentType: configJSON.getAppointmentDetailsContentType,
            method: configJSON.getAppointmentMethod,
            endPoint: `${configJSON.getAppointmentDetailsEndPoint}?appointment_id=${this.state.appointmentId}`
        });
    };

    getAppointmentDetailsSuccessCallBack = (response: AppointmentDataType) => {
        this.setState({appointmentData:response})
        let parsedData: string[] = []
        let tempData: Array<string> = JSON.parse(response.data.attributes.symtoms);
        tempData?.forEach((symtoms: string) => {
            parsedData.push(symtoms)
        })
    
        this.setState({
            symptomsData: parsedData,
            patientId: response.data.attributes.patient_id
        });
        localStorage.setItem("patient_health",response.data.attributes.health_id)
    };

    getAppointmentDetailsFailureCallBack = async (error: InvalidResponseType) => {
        this.setState({
            errorRecordFound: true
        });
    };

    getProfileDetailsfunction = async () => {
        this.getProfileDetailsCallId = this.apiCall({
            contentType: configJSON.getProfileDetailsContentType,
            method: configJSON.getProfileDetailsMethod,
            endPoint: `${configJSON.getProfileDetailsEndPoint}`
        });
    };

    getProfileDetailsSuccessCallBack = (response: DoctorDetailsType) => {
        this.setState({
            doctorProfileData: response
        })
    };

    handelDoctorProfilePopupShowFunction = () => {
        this.setState({ showPopup: !this.state.showPopup });
    };

    handleDoctorLogout = () => {
        localStorage.clear();
        notification["success"]({
            message: "Logout successfully",
        });
        this.props.history.push("/");
    };

    handleDoctorDetailsPage = () => {
        this.props.history.push("/doctorprofiledetails");
    };

    handleEditDoctorProfile = () => {
        this.props.history.push("/editdoctordetails")
    };

    handleOpenDeleteModal = () => {
        this.setState({ deleteModal: false });
    };

    handleOpenModal = () => {
        this.setState({ deleteModal: true });
    };

    handleCloseDeleteModal = () => {
        this.setState({ deleteModal: false });
    };

    handleConsultation = (id: number, patientid: number) => {
        this.props.history.push({
            pathname:"/viewprescription", 
            state: {appointments_id: id, patient_Id: patientid}
        });
    };

    handleNavigate = (id:number,patientid: number) => {
        this.props.history.push({
            pathname:"/viewprescription",
            state: {appointments_id: id, patient_Id: patientid}
        });
    };

    navigateInvoicePage = (id:number) => {
        this.props.history.push({
            pathname:"/invoicedetails",
            state: {appointments_id: id}
        });
    };

    handlePopupClose = () => {
        this.setState({
            showPopup: false
        });
    };

    handleMedicalHistory = (id: number , apoointId: number) => {
        this.props.history.push({
            pathname:"/viewprescription", 
            state: {
                patient_Id: id,
                appointments_id: apoointId,
                medicalHistoryTab: "Case"
            }
        });
    };

    navigateToVideoCallScreen=()=>{
        this.props.history.push({
            pathname:"/videoscreen", 
            state: {
                item:this.state.appointmentData  
            }
        });
    }

    navigateToViewPrescriptionScreen=()=>{
        this.props.history.push({
            pathname:"/viewPrescriptionScreen", 
            state: {
                item:this.state.appointmentData  
            }
        });
    }

    navigateToAddPrescriptionScreen=()=>{
        this.props.history.push({
            pathname:"/AddPrescriptionScreen", 
            state: {
                patient_Id:this.state.patientId,
                appointments_id: this.state.appointmentId,
            }
        });
    }
    // Customizable Area End
}
