import React from "react";
// Customizable Area Start
import { Box, Grid, Modal, styled } from '@material-ui/core';
import { FaGreaterThan, FaUserCircle } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { BsFillTrash3Fill, BsFillTelephoneFill } from 'react-icons/bs';
import AppointmentsDetailsController, {
    Props
} from "./AppointmentsDetailsController.web";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
let config = require("../../../framework/src/config.js").baseURL;
import { Link } from 'react-router-dom';
import { pulse, heart, weight } from "./assets"
// Customizable Area End

export default class AppointmentsDetails extends AppointmentsDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderProfileHeder = () => {
        return (
            <ContainerBox>
                <HeaderHeadingBox>
                    <Heading>
                        Appointment Details
                    </Heading>
                    <HeaderActionButtonBox>
                        <HeaderIcon>
                            <MdModeEdit />
                        </HeaderIcon>
                        <HeaderIcon>
                            <BsFillTrash3Fill onClick={this.handleOpenModal} data-test-id="deleteappointmentid" />
                        </HeaderIcon>
                    </HeaderActionButtonBox>
                </HeaderHeadingBox>
                <ProfileDetailsBox>
                    <DoctorProfileBox>
                        <Box>
                            {
                                this.state.appointmentData.data.attributes.patient_profile_image ? <ProfilePhoto src={config + this.state.appointmentData.data.attributes.patient_profile_image} /> : <User />
                            }
                        </Box>
                        <Box>
                            <DoctorNameText>{this.state.appointmentData.data.attributes.patient_full_name}</DoctorNameText>
                            <DoctorDetailsText component={"span"}>{this.state.appointmentData.data.attributes.patient_gender} | {this.state.appointmentData.data.attributes.patient_year} | ID {this.state.appointmentData.data.attributes.health_id}</DoctorDetailsText>
                        </Box>
                    </DoctorProfileBox>
                    <Box>
                        <PhoneNoTextHeading>Phone No:</PhoneNoTextHeading>
                        <PhoneDetailsBox>
                            <PhoneIconBox>
                                <BsFillTelephoneFill />
                            </PhoneIconBox>
                            <Box>{this.state.appointmentData.data.attributes.patient_phone_number}</Box>
                        </PhoneDetailsBox>
                    </Box>
                </ProfileDetailsBox>
            </ContainerBox>
        )
    }

    renderNavigationMenu = () => {
        return (
            <>
                <NavigationTabBox>
                    <NavigationScreenHeading data-test-id="viewPrescriptionTestId">View Prescriptions</NavigationScreenHeading>
                    <NavigationTabButton data-test-id="viewPrescriptionRightArrowButtonId" onClick={() => this.handleNavigate(this.state.appointmentId,this.state.patientId)}><RightArrow /></NavigationTabButton>
                </NavigationTabBox>
                <NavigationTabBox>
                    <NavigationScreenHeading data-test-id="medicaltextid">Medical History</NavigationScreenHeading>
                    <NavigationTabButton data-test-id="medicalId" onClick={() => this.handleMedicalHistory(this.state.patientId,this.state.appointmentId)}><RightArrow /></NavigationTabButton>
                </NavigationTabBox>
                <NavigationTabBox>
                    <NavigationScreenHeading>View Lab Reports</NavigationScreenHeading>
                    <NavigationTabButton><RightArrow /></NavigationTabButton>
                </NavigationTabBox>
                <NavigationTabBox>
                    <NavigationScreenHeading data-test-id="invoicetextid">Invoice</NavigationScreenHeading>
                    <NavigationTabButton data-test-id="invoiceId" onClick={() => this.navigateInvoicePage(this.state.appointmentId)}><RightArrow /></NavigationTabButton>
                </NavigationTabBox>
            </>
        )
    }

    renderDeleteAppointmentModal = () => {
        return (
            <>
                <DeleteAppointmentModal
                    data-test-id="deleteModalId"
                    open={this.state.deleteModal}
                    onClose={this.handleOpenDeleteModal}
                >
                    <ModalComponent>
                        <Box component={"h4"} data-test-id="deletetextid">Delete</Box>
                        <Box component={"p"}>
                            Are you sure you want to delete the appointment with the patient?
                        </Box>
                        <ButtonBox>
                            <NoButton onClick={this.handleCloseDeleteModal} data-test-id="closemodalid">No</NoButton>
                            <YesButton>Yes, I'm sure</YesButton>
                        </ButtonBox>
                    </ModalComponent>
                </DeleteAppointmentModal>
            </>
        )
    }

    renderAppointmentsDetails = () => {
        return (
            <>
                <ContainerBox>
                    <Box pt={2} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <PageHeading>Symptoms</PageHeading>
                            {
                                this.state.symptomsData.map((item: string, index: number) => {
                                    return (
                                        <SymptomsTag key={index}>{item}</SymptomsTag>
                                    )
                                })
                            }
                        </Grid>
                        <ConsultationButtonBox item xs={12} md={6}>
                            <StartConsultationButton data-test-id="startConsultaionButtonId" onClick={()=>this.navigateToVideoCallScreen()} >
                                Start the consultation
                            </StartConsultationButton>
                        </ConsultationButtonBox>
                    </Grid>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <PageHeading>Health Status</PageHeading>
                            <HealthStatusOuterBox>
                                <HealthStatusBox>
                                    <HealthStatusIcon>
                                        <img src={pulse} />
                                    </HealthStatusIcon>
                                    <HealthStatusText>
                                        130/90
                                    </HealthStatusText>
                                    <HealthStatusSpan>
                                        mmHg
                                    </HealthStatusSpan>
                                </HealthStatusBox>
                                <HealthStatusBox>
                                    <HealthStatusIcon>
                                        <img src={heart} />
                                    </HealthStatusIcon>
                                    <HealthStatusText>
                                        96
                                    </HealthStatusText>
                                    <HealthStatusSpan>
                                        bpm
                                    </HealthStatusSpan>
                                </HealthStatusBox>
                                <HealthStatusBox>
                                    <HealthStatusIcon>
                                        <img src={weight} />
                                    </HealthStatusIcon>
                                    <HealthStatusText>
                                        76
                                    </HealthStatusText>
                                    <HealthStatusSpan>
                                        Kgs
                                    </HealthStatusSpan>
                                </HealthStatusBox>
                            </HealthStatusOuterBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <PageHeading>Last Case: 24jan,2021</PageHeading>
                            <LastCaseOuterBox>
                                <LastCaseBox>
                                    <LastCaseHeading>Type 2 Diabetes - Routine</LastCaseHeading>
                                    <LastSymptomsBox>
                                        <LastCaseInnerHeading>Symptoms</LastCaseInnerHeading>
                                        <Box>
                                            <SymptomsTag>Diabetes</SymptomsTag>
                                            <SymptomsTag>Heart attack</SymptomsTag>
                                            <SymptomsTag>Cancer</SymptomsTag>
                                        </Box>
                                    </LastSymptomsBox>
                                </LastCaseBox>
                                <PrescriptionBox>
                                    <PrescriptionHeading>Prescription:</PrescriptionHeading>
                                    <PrescriptionText>Folvite 500mg, Flucold 90mg</PrescriptionText>
                                </PrescriptionBox>
                            </LastCaseOuterBox>
                        </Grid>
                    </Grid>
                    {this.renderNavigationMenu()}
                </ContainerBox>
            </>
        )
    }

    renderNavPage = () => {
        return (
            <HeaderContainer>
                <Link to={"/doctorhome"}><PageHeader>Home</PageHeader></Link>
                <Link to={"/doctorschedule"}><ActivePageHeader>Schedule</ActivePageHeader></Link>
                <Link to={"/addpatient"}><PageHeader>Add Patient</PageHeader></Link>
                <PageHeader>Chats</PageHeader>
            </HeaderContainer>
        );
    };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavbarCommonComponent data-test-id="showProfileHeaderId" handleProfile={this.handelDoctorProfilePopupShowFunction} />
                <DoctorProfilePopup data-test-id="handleDoctorLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} showProfile={this.handleDoctorDetailsPage} doctorDetailsData={this.state.doctorProfileData} handleEditProfile={this.handleEditDoctorProfile} handleClosePopup={this.handlePopupClose} />
                {this.renderDeleteAppointmentModal()}
                {this.renderNavPage()}
                <DoctorProfileHeaderBox>
                    {this.renderProfileHeder()}
                </DoctorProfileHeaderBox>
                {this.renderAppointmentsDetails()}
                <FooterDoctor />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const DoctorProfileHeaderBox = styled(Box)({
    backgroundColor: "#f1f7ff",
    padding: "20px 0px"
});

const NavigationTabBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 2px",
    margin: "10px 0px",
    boxShadow: " 0px 3px 5px 0px rgb(241, 247, 255)",
    "-webkit-box-shadow": "0px 3px 5px 0px rgba(241, 247, 255, 0.75)",
    "-moz-box-shadow": "0px 3px 5px 0px rgba(241, 247, 255, 0.75)"
});

const NavigationScreenHeading = styled(Box)({
    fontSize: "14px",
    fontWeight: 600
});

const NavigationTabButton = styled("button")({
    border: "none",
    display: "flex",
    padding: "5px",
    backgroundColor: "transparent",
    cursor: "pointer"
});

const PageHeading = styled(Box)({
    fontSize: "15px",
    fontWeight: 700,
    margin: "10px 0px"
});

const HealthStatusOuterBox = styled(Box)({
    width: "100%",
    minHeight: '150px',
    borderRadius: "5px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    boxShadow: "0px 0px 3px 1px rgba(128, 128, 128, 0.75)",
    "-webkit-box-shadow": "0px 0px 3px 1px rgba(128, 128, 128, 0.75)",
    "-moz-box-shadow": "0px 0px 3px 1px rgba(128, 128, 128, 0.75)",
});

const HealthStatusBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
});

const HealthStatusIcon = styled(Box)({
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    backgroundColor: "#f1f7ff",
    color: "#3a79c0",
    fontSize: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const HealthStatusText = styled(Box)({
    fontSize: "16px",
    fontWeight: 800,
    margin: "2px 0px"
});

const HealthStatusSpan = styled(Box)({
    fontSize: "10px",
});

const LastCaseOuterBox = styled(Box)({
    width: "100%",
    minHeight: '150px',
    borderRadius: "5px",
    padding: "10px",
    boxSizing: "border-box",
    boxShadow: "0px 0px 3px 1px rgba(128, 128, 128, 0.75)",
    "-webkit-box-shadow": "0px 0px 3px 1px rgba(128, 128, 128, 0.75)",
    "-moz-box-shadow": "0px 0px 3px 1px rgba(128, 128, 128, 0.75)",
});

const StartConsultationButton = styled(Box)({
    color: "#ffffff",
    backgroundColor: "#3a79c0",
    fontSize: "15px",
    borderRadius: "20px",
    width: "250px",
    textAlign: "center",
    padding: "10px 0px",
    fontWeight: "bold",
    cursor:"pointer"
});

const SymptomsTag = styled(Box)({
    padding: "10px 25px",
    borderRadius: "20px",
    fontWeight: 500,
    backgroundColor: "#fef4c7",
    display: "inline-block",
    fontSize: "12px",
    margin: "5px",
    "@media (max-width:1000px)": {
        padding: "10px 20px",
        fontSize: "12px",
    },
    "@media (max-width:800px)": {
        padding: "8px 20px",
        fontSize: "11px",
    },
    "@media (max-width:500px)": {
        padding: "5px 15px",
        fontSize: "10px",
    }
});

const ConsultationButtonBox = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "@media (max-width:960px)": {
        justifyContent: "center",
    }
});

const HeaderIcon = styled(Box)({
    color: "#3a79c0",
    fontSize: "20px",
    cursor: "pointer"
});

const HeaderHeadingBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center'
});

const HeaderActionButtonBox = styled(Box)({
    display: "flex",
    alignItems: 'center',
    columnGap: "5px"
});

const Heading = styled(Box)({
    fontSize: '16px',
    fontWeight: "bold"
});

const ProfilePhoto = styled("img")({
    height: "100px",
    width: "100px",
    borderRadius: "100%"
});

const ProfileDetailsBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0px"
});

const DoctorNameText = styled(Box)({
    color: "#3a79c0",
    fontSize: "15px",
    fontWeight: "bold"
});

const DoctorDetailsText = styled(Box)({
    fontSize: "11px",
    color: "#808080",
    fontWeight: 500
});

const DoctorProfileBox = styled(Box)({
    display: "flex",
    columnGap: "20px",
    "@media (max-width:450px)": {
        flexDirection: "column"
    }
});

const PhoneNoTextHeading = styled(Box)({
    color: "#3a79c0",
    fontSize: "15px",
    fontWeight: "bold"
});

const PhoneIconBox = styled(Box)({
    color: "#3a79c0",
    fontSize: "15px",
});

const PhoneDetailsBox = styled(Box)({
    display: "flex",
    margin: "5px 0px",
    alignItems: "center",
    columnGap: '5px',
    fontSize: '14px'
});

const LastCaseBox = styled(Box)({
    width: "89%",
    margin: "0px auto",
    borderBottom: '1px solid #D3D3D3'
});

const LastCaseInnerHeading = styled(Box)({
    fontSize: "11px",
    color: "#808080",
    margin: "15px 0px"
});

const LastSymptomsBox = styled(Box)({
    display: "flex",
});

const LastCaseHeading = styled(Box)({
    fontSize: "15px",
    fontWeight: 600,
    margin: "5px 0px"
});

const PrescriptionBox = styled(Box)({
    display: "flex",
    width: "80%",
    margin: "10px auto",
    columnGap: '10px'
});

const PrescriptionHeading = styled(Box)({
    color: "#808080",
    fontSize: "14px",
    "@media (max-width:960px)": {
        fontSize: "13px"
    },
    "@media (max-width:600px)": {
        fontSize: "12px"
    }
});

const PrescriptionText = styled(Box)({
    fontWeight: 400,
    fontSize: "14px",
    "@media (max-width:960px)": {
        fontSize: "13px"
    },
    "@media (max-width:600px)": {
        fontSize: "12px"
    }
});

const DeleteAppointmentModal = styled(Modal)({
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
});

const ModalComponent = styled(Box)({
    textAlign: "center",
    padding: "10px 100px",
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    outline: "none",
    margin: "20px",
    "@media (max-width:800px)": {
        padding: "10px 50px",
    },
    "@media (max-width:400px)": {
        padding: "10px 20px",
    }
});

const ButtonBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    margin: "10px 0px",
    columnGap: "10px"
});

const NoButton = styled("button")({
    color: "#3b78c2",
    border: "2px solid #3b78c2",
    borderRadius: "25px",
    padding: "10px",
    width: "140px",
    backgroundColor: "#ffffff",
    fontWeight: "bold",
    cursor: "pointer",
    "@media (max-width:800px)": {
        fontSize: "13px",
        width: "130px"
    }
});

const YesButton = styled("button")({
    backgroundColor: "#3b78c2",
    color: "white",
    borderRadius: "25px",
    border: "2px solid #3b78c2",
    padding: "10px",
    width: "140px",
    fontWeight: "bold",
    cursor: "pointer",
    "@media (max-width:800px)": {
        fontSize: "13px",
        width: "130px"
    }
});

const PageHeader = styled("p")({
    color: "black",
    fontSize: "16px",
    fontWeight: 600
});

const HeaderContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    width: "89%",
    margin: "0 auto"
});

const ActivePageHeader = styled("p")({
    color: "#4d96dd",
    fontSize: "16px",
    fontWeight: 600
});

const User = styled(FaUserCircle)({
    width: "100px",
    height: "100px",
    color: "#8080806b"
});

const RightArrow = styled(FaGreaterThan)({
    color: "black",
    fontWeight: 400
});

const ContainerBox = styled(Box)({
    width: "89%",
    margin: "0 auto"
});
// Customizable Area End
