import React from 'react'
// Customizable Area Start
import { merilLogo, rupaypese, doctor,searchimg,location,notification, Default } from "../../blocks/splashscreen/src/assets";
import {AiOutlineShoppingCart,AiOutlineDown} from "react-icons/ai"
import { Link } from 'react-router-dom';
import Location from "../../blocks/location/src/Location.web"
import Elasticsearch2 from '../../blocks/elasticsearch2/src/Elasticsearch2.web';
let config:string = require('../../framework/src/config.js').baseURL;


// Customizable Area End

type NavbarCommonComponentProps = {
    // Customizable Area Start
    handleProfile :any
    
    // Customizable Area End
};


const NavbarCommonComponent = ({
    // Customizable Area Start
    handleProfile
    // Customizable Area End
}: NavbarCommonComponentProps) => {
    // Customizable Area Start
    const profile_pic = localStorage.getItem(
        "profile_pic"
    );
    // Customizable Area Start
    const  renderHeaderPart = () => {
        const isUserLogin = localStorage.getItem("isLogin");
        const isPatientGoogleLogin = JSON.parse(localStorage.getItem("isPatientGoogleLogin") as any) ;
        const isDoctorGoogleLogin = JSON.parse(localStorage.getItem("isDoctorGoogleLogin") as any) ;
        const profilePic = localStorage.getItem("profile_pic");
        const health_id = localStorage.getItem("health_id")
        const is_kyc = localStorage.getItem("is_kyc");
        return (
                <nav className="navbar navbar-expand-lg" >
                    <div className="container-fluid header_orveride  ">
                        <div className='headerLogoBox'>
                        <Link to={is_kyc === "approved" ? "/doctorhome" : is_kyc === "pending"? "/doctorprofiledetails": health_id == undefined ? "/":  "/patienthome" }><img className='headerLogoImg' src={merilLogo} /></Link>
                        </div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse SearchLocationBox" id="mynavbar">
                            <ul className="navbar-nav me-auto list">
                                <li className="nav-item">
                                    <div className='headerSearchBox'>
                                        <div className='locationBox'>
                                            <Location/>
                                            
                                        </div>
                                        <div className='searchBoxDoc'>
                                            <span>
                                                <img src={searchimg} />
                                            </span>
                                            <Elasticsearch2 navigation="" id='' />
                                        </div>
                                    </div>
                                </li>

                            </ul>
                            {
                                isUserLogin || isPatientGoogleLogin || isDoctorGoogleLogin ?

                                    <div className={window.location.pathname === "/doctorhome" ? "navbarDoctorFeature navbarRightFeature" : "navbarRightFeature"} >
                                        {(window.location.pathname === "/doctorhome" || window.location.pathname === "/doctorschedule" || window.location.pathname === "/doctorprofiledetails" || window.location.pathname === "/editdoctordetails" || window.location.pathname === "/appointmentsdetails" || window.location.pathname === "/viewprescription" || window.location.pathname === "/invoicedetails" || window.location.pathname === "/mypatient" || window.location.pathname === "/scheduledetails" || window.location.pathname === "/addpatient" || window.location.pathname === "/myclinics" || window.location.pathname === "/uploaddoc" || window.location.pathname === "/viewdocument" || window.location.pathname === "/feespackagemanagement" || window.location.pathname === "/packagedetails" || window.location.pathname === "/termsandconditions" || window.location.pathname === "/privacypolicy" || window.location.pathname === "/Summary" || window.location.pathname === "/contactus") ?
                                            <div className="navbarDoctorHome removeWidth">
                                                <img src={notification} className='notificationImg'/>
                                            </div>
                                            :
                                            <div className="navbarRightIconContainer">
                                                <img src={rupaypese} alt="rupes" className="rupayImage" />
                                                <img src={notification} className='notificationImg'/>
                                                {/* <AiOutlineShoppingCart   className='linkCartImg'/> */}
                                            </div>
                                        }
                                        <div className="navbarUserProfileContianer" onClick={()=> handleProfile()}>
                                            <div className="profilePhotoContainer">
                                                {profile_pic ?(
                                                 <img src={ profile_pic.includes("https") ?profile_pic: `${config}${profile_pic}`} alt="userImage"/>
                                                ):(<img alt="defaulr" src={Default} />)

                                                }
                                            </div>
                                            <AiOutlineDown  className="downIcon" />
                                        </div>
                                    </div>:
                                    <div className='headerLinkBox'>
                                        <Link to="/login">
                                            <span className='linkText'>
                                                Login
                                            </span>
                                        </Link>
                                        <span className='particition'>/</span>
                                        <Link to="/signup">
                                            <span className='linkText'>
                                            Sign Up
                                            </span>
                                        </Link>
                                    </div>

                            }

                        </div>
                    </div>
                </nav>

        )
    }
    // Customizable Area End
    // Customizable Area Start
    return (
        <>
            <div className='landingPageContainer'>
                {renderHeaderPart()}
            </div>
        </>
    )
}
// Customizable Area End


// Customizable Area Start
export default NavbarCommonComponent;
// Customizable Area End