import React from "react";
// Customizable Area Start
import {
    Box,
    Container,
    Paper,
    Typography,
    styled
} from "@material-ui/core";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
import { Link } from "react-router-dom";
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import InvoiceDetailsController, {
    Props
} from "./InvoiceDetailsController.web";
import { AiOutlineDownload } from "react-icons/ai"
import { pdfIcon } from "./assets";
import moment from "moment";
// Customizable Area End

export default class InvoiceDetails extends InvoiceDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderNavigatePage = () => {
        return (
            <EditPageHeader>
                <Link to={"/doctorhome"}><PageHeader>Home</PageHeader></Link>
                <Link to={"/doctorschedule"}><ActivePageHeader>Schedule</ActivePageHeader></Link>
                <Link to={"/addpatient"}><PageHeader>Add Patient</PageHeader></Link>
                <PageHeader>Chats</PageHeader>
            </EditPageHeader>
        )
    };

    renderContainer = () => {
        return (
            <InvoicesContainer>
                <PageTitleContainer>
                    <PageTitle variant="h5">Invoices</PageTitle>
                    {
                        this.state.isInvoiceOpen &&
                        <DownloadBtn onClick={this.getInvoicePdfDetailsfunction} data-test-id="invoicepdfid">
                            <DownloadIcon />  <Box component={"span"} data-test-id="invoicetext">Download as PDF</Box>
                        </DownloadBtn>
                    }
                </PageTitleContainer>
                {
                    this.state.isInvoiceOpen ?
                        this.renderInvoiceDetails()
                        :
                        this.renderInvoiceList()
                }
            </InvoicesContainer>
        )
    };

    renderInvoiceList = () => (
        <Inovice onClick={this.onClickHandler} data-test-id="invoicedetailsid">
            <InvoiceLeft>
                <PdfIcon src={pdfIcon} />
                <InvoiceTitleId>
                    <InvoiceTitle variant="h6">{this.state.invoiceDetailsData.data.attributes.patient_full_name} - {moment(this.state.invoiceDetailsData.data.attributes.appointment_date).format("DD/MM/YYYY")}</InvoiceTitle>
                    <InoviceId>#{this.state.invoiceDetailsData.data.attributes.health_id}</InoviceId>
                </InvoiceTitleId>
            </InvoiceLeft>
            <InvoicePrice variant="h6">₹{this.state.invoiceDetailsData.data.attributes.original_price}</InvoicePrice>
        </Inovice>
    );

    renderInvoiceDetails = () => (
        <>
            <Details>
                <DetailsTitle> Invoice details</DetailsTitle>
                <DescWrapper>
                    <DescTitle>Date & time:</DescTitle>
                    <DescSubTitle>{this.state.invoiceDetailsData.data.attributes.appointment_date},{this.state.invoiceDetailsData.data.attributes.time_slot}</DescSubTitle>
                </DescWrapper>
                <DescWrapper>
                    <DescTitle>Mode of consultation:</DescTitle>
                    <DescSubTitle>{this.state.invoiceDetailsData.data.attributes.consultation_category}</DescSubTitle>
                </DescWrapper>
                <DescWrapper>
                    <DescTitle>physician's name:</DescTitle>
                    <DescSubTitle>Dr.{this.state.invoiceDetailsData.data.attributes.doctor_full_name}</DescSubTitle>
                </DescWrapper>
            </Details>
            <Details>
                <DetailsTitle> Patient details</DetailsTitle>
                <DescWrapperSpaceBetween>
                    <Box>
                        <DescTitle>pateint name:</DescTitle>
                        <DescSubTitle data-test-id="invoicedetailspatientid">{this.state.invoiceDetailsData.data.attributes.patient_full_name}</DescSubTitle>
                    </Box>
                    <FlexEnd>
                        <DescTitle>s/o | d/o | w/o:</DescTitle>
                        <DescSubTitle>Kalpana Yadav</DescSubTitle>
                    </FlexEnd>
                </DescWrapperSpaceBetween>
                <DescWrapperSpaceBetween>
                    <Box>
                        <DescTitle>age:</DescTitle>
                        <DescSubTitle>{this.state.invoiceDetailsData.data.attributes.patient_year}</DescSubTitle>
                    </Box>
                    <FlexEnd>
                        <DescTitle>sex:</DescTitle>
                        <DescSubTitle>{this.state.invoiceDetailsData.data.attributes.patient_gender}</DescSubTitle>
                    </FlexEnd>
                </DescWrapperSpaceBetween>
                <DescWrapper>
                    <DescTitle>patient address:</DescTitle>
                    <DescSubTitle>{this.state.invoiceDetailsData.data.attributes.patient_address}</DescSubTitle>
                </DescWrapper>
            </Details>
            <Details>
                <DetailsTitle>Medical details</DetailsTitle>
                <DescWrapperSpaceBetween>
                    <Box>
                        <DescTitle>health insurance no:</DescTitle>
                        <DescSubTitle>973787575</DescSubTitle>
                    </Box>
                    <FlexEnd>
                        <DescTitle>patient id:</DescTitle>
                        <DescSubTitle>{this.state.invoiceDetailsData.data.attributes.patient_id}</DescSubTitle>
                    </FlexEnd>
                </DescWrapperSpaceBetween>
                <DescWrapper>
                    <DescTitle>health care provider:</DescTitle>
                    <DescSubTitle>-Nisha ji</DescSubTitle>
                </DescWrapper>
                <DescWrapper>
                    <DescTitle>health card no:</DescTitle>
                    <DescSubTitle>234556788</DescSubTitle>
                </DescWrapper>
            </Details>
            <Details>
                <BillingTitle>Billing details</BillingTitle>
                <DescWrapperSpaceBetween>
                    <DescTitle>subtotal</DescTitle>
                    <DescSubTitle>₹{this.state.invoiceDetailsData.data.attributes.fees}</DescSubTitle>
                </DescWrapperSpaceBetween>
                <DescWrapperSpaceBetween>
                    <DescTitle>discount</DescTitle>
                    <DescSubTitle>₹{this.state.invoiceDetailsData.data.attributes.discount_price}</DescSubTitle>
                </DescWrapperSpaceBetween>
                <DescWrapperSpaceBetween>
                    <DescTitle>total amount</DescTitle>
                    <Count>₹{this.state.invoiceDetailsData.data.attributes.fees-this.state.invoiceDetailsData.data.attributes.discount_price}</Count>
                </DescWrapperSpaceBetween>
            </Details>
        </>
    );
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NavbarCommonComponent data-test-id="invoiceShowDocProfile" handleProfile={this.handelDoctoPopUp} />
                <DoctorProfilePopup data-test-id="invoiceProfileId" profileData={this.state.showPopup} logoutFunctiuon={this.handleUserLogout} showProfile={this.handleDocDetailsPage} doctorDetailsData={this.state.doctorProfileData} handleEditProfile={this.handleEditDocProfile} handleClosePopup={this.handlePopupClose} />
                <ContainerBox>
                    {this.renderNavigatePage()}
                    {this.renderContainer()}
                </ContainerBox>
                <FooterDoctor />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const EditPageHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    margin: "0 auto"
});

const PageHeader = styled("p")({
    color: "black",
    fontSize: "16px",
    fontWeight: 600
});

const ContainerBox = styled(Box)({
    width: "89%",
    margin: "0 auto"
});

const InvoicesContainer = styled(Container)({
    padding: "20px 0"
});

const PageTitleContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
    "@media(max-width:460px)": {
        flexDirection: "column",
    }
});

const PageTitle = styled(Typography)({
    fontSize: "18px"
});

const DownloadBtn = styled("button")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    backgroundColor: "#3b78c2",
    padding: "10px 0",
    flexBasis: "340px",
    color: "#fff",
    fontSize: "20px",
    borderRadius: "40px",
    textAlign: "center",
    border: "none",
    cursor: "pointer",
    "@media(max-width:600px)": {
        fontSize: "16px",
        flexBasis: "100%",
        padding: "10px 20px"
    }
});

const Inovice = styled(Paper)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    marginTop: "20px",
    cursor: "pointer",
    boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.2)"
});

const InvoiceLeft = styled(Box)({
    display: "flex",
    alignItems: "center"
});

const PdfIcon = styled("img")({
    height: "40px",
    width: "40px"
});

const InvoiceTitleId = styled(Box)({
    marginLeft: "10px"
});

const InvoiceTitle = styled(Typography)({
    textTransform: "capitalize",
    fontSize: "16px",
    "@media(max-width:600px)": {
        fontSize: "16px"
    }
});

const InoviceId = styled(Typography)({
    color: "#8e8e8e"
});

const InvoicePrice = styled(Typography)({
    fontSize: "16px",
    marginLeft: "auto",
    "@media(max-width:600px)": {
        fontSize: "16px"
    }
});

const DownloadIcon = styled(AiOutlineDownload)({
    fontSize: "30px",
    "@media(max-width:600px)": {
        fontSize: "20px"
    }
});

const Details = styled(Paper)({
    padding: "16px",
    marginTop: "30px",
    boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.2)"
});

const DetailsTitle = styled(Typography)({
    color: "#3b78c2",
    textTransform: "uppercase",
    fontSize: "16px"
});

const DescWrapper = styled(Box)({
    marginTop: "20px"
});

const DescTitle = styled(Typography)({
    color: "#8e8e8e",
    textTransform: "uppercase",
    fontSize: "17px",
    "@media(max-width:600px)": {
        fontSize: "16px"
    }
});

const DescSubTitle = styled(Typography)({
    fontSize: "16px",
    textTransform: "capitalize",
    "@media(max-width:600px)": {
        fontSize: "16px"
    }
});

const DescWrapperSpaceBetween = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px"
});

const FlexEnd = styled(Box)({
    textAlign: "right"
});

const BillingTitle = styled(Typography)({
    color: "#3b78c2",
    textAlign: "center",
    textTransform: "uppercase"
});

const Count = styled(Typography)({
    color: "#3b78c2",
    display: "inline",
    "@media(max-width:600px)": {
        fontSize: "16px",
    }
});

const ActivePageHeader = styled("p")({
    color: "#4d96dd",
    fontSize: "16px",
    fontWeight: 600
});
// Customizable Area End