
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { RouterProps } from 'react-router';
import React from "react"
import { withLoaderProps } from "../../../components/src/MerilLoader.web";
import {DatePickerProps, notification } from "antd";


import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

export type Props = RouterProps &
    withLoaderProps & {
        // Customizable Area Start
        id: string;
        history: any;
        location: any;
        // Customizable Area End
    }
 // Customizable Area End


export interface S {
  // Customizable Area Start
  first_name:string,
  full_name:string,
  last_name: string,
  specialization: string,
  qualification:string,
  image:string,
  rating:string,
  experience:string,
  language: any,
 online_consultation_choose:boolean
 selected_date:any,
 selected_time_slot:string,
 mode_type:string,
 promo_code:string,
 consultation_type:string,
 pick_time:string,
 time_slot:string,
 appointment_date:string,
 doctor_id:string,
 isTimeSlotSelected:boolean,
 timeSlotData:any,
 active_time_slot_section:any,
 active_consultation_type:string,
 activeTab:string,
 selectedTimeSlotMorning:string,
 doctorExp:string,
 doctorRating:number,
 doctorRatingList:any,
 consultation_fees:any,
 evening_time_slot:any,
 onlineTimeSlot:any,
 onlineConsultationData:any,
 bookingAppointmentDetails:any,
 isLoading:boolean,
 doctorLanguage:any,
 appointmentNavigationList:any,
 doctorId:any,
 token:any,
 showPopup:boolean,
 isLoginUser:boolean,
 showClinicDetails:boolean,
 clinicDetails:any,
 about:string,
 rating_average:number,
 reviews_count:number,
 doctorDetails:any,
 contentHeight:number
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DoctorProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDoctorListApiCallId: any;
  getTimeSlotApiCallId:any;
  getVirtualTimeSlotApiCallId:any;
  getInClinicTimeSlotApiCalledId:any;
  bookingAppointmentSlotApiCalledId:any;
  getOnlineConsultationDataApiCalledId:any
  myRef:any
  clinicDetailsApiCalledId:any
  getDoctorDetailsApiCalledId:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.myRef = React.createRef();
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);
    this.state = {
      // Customizable Area Start
      first_name:"",
      last_name:"",
      full_name:"",
      image:"",
      specialization:"",
      language:[],
      qualification:"",
      rating:"",
      experience:"",
      isTimeSlotSelected:false,
       online_consultation_choose:false,
       selected_date: moment().format('DD/MM/YYYY'),
       selected_time_slot:'',
       mode_type:'Virtual',
       promo_code:'',
       consultation_type:'',
       pick_time:'',
       time_slot:'',
       appointment_date:'',
       doctor_id:'',
       timeSlotData:'',
       active_time_slot_section:"Morning",
       active_consultation_type:"",
       activeTab:"Virtual",
       selectedTimeSlotMorning:"",
       doctorExp:"",
       doctorRating:0,
       doctorRatingList:[],
       consultation_fees:"",
       evening_time_slot:[],
       onlineTimeSlot:[],
       onlineConsultationData:[],
       bookingAppointmentDetails:null,
       isLoading:false,
       doctorLanguage:[],
       appointmentNavigationList:[],
       doctorId:"",
       token:"",
       showPopup:false,
       isLoginUser:true,
       showClinicDetails:false,
       clinicDetails:[],
       about:"",
       rating_average:0,
       reviews_count:0,
       doctorDetails:"",
       contentHeight:0
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let doctorResponseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
            
      );
    

      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

        if(apiRequestCallId !=null){
          switch(true){
            case apiRequestCallId === this.getOnlineConsultationDataApiCalledId:
              this.filterOnlineConsultationDetails(doctorResponseJson.data)
              break;

            case apiRequestCallId === this.getInClinicTimeSlotApiCalledId:
              this.setState({evening_time_slot:doctorResponseJson?.data});
              this.showToast(doctorResponseJson)
              break;

            case apiRequestCallId === this.getVirtualTimeSlotApiCallId:
              this.setState({onlineTimeSlot:doctorResponseJson?.data});
              this.showToast(doctorResponseJson)
              break;

            case apiRequestCallId === this.bookingAppointmentSlotApiCalledId:
              this.handleBookingAppointmentResponse(doctorResponseJson)
              break;

            case apiRequestCallId === this.clinicDetailsApiCalledId:
              if(doctorResponseJson?.message){
                notification["error"]({
                  message: doctorResponseJson.message})
              }else{
                this.handleDoctorClinicData(doctorResponseJson);
              }
              break;
            case apiRequestCallId === this.getDoctorDetailsApiCalledId:
              this.setState({doctorDetails:doctorResponseJson.data,isLoading:false});
              break
            default:
              
          }
            
        }

  }
    // Customizable Area End
  };

  // Customizable Area Start


  showToast(doctorResponseJson:any){
    if(doctorResponseJson?.errors[0].message){
      notification["error"]({
        message: doctorResponseJson.errors[0].message})

    }
  }

  handleDoctorClinicData=(response:any)=>{
     this.setState({clinicDetails:response.data});
  }

  handleBookingAppointmentResponse=(doctorResponseJson:any)=>{

   if(doctorResponseJson.data){
    this.setState({bookingAppointmentDetails:doctorResponseJson.data},()=>
    this.props.history.push({pathname:"/patient-appointment-checkout", state:{ doctorData:this.state,    navigateList: [
      { name: localStorage.getItem("doctor_category"), route: "/patient-appointment" },
      { name:"DoctorProfile", route: "/patient-appointment" },
      { name:"Checkout", route: "/patient-appointment-checkout" },
    ],}})
    )

   }

  }

 
  filterOnlineConsultationDetails(doctorResponseJson:any){
    let onlineConsultation_data = doctorResponseJson.filter((consultation:any)=>consultation.attributes.consultation_category === "Online Consultation")

    this.setState({onlineConsultationData:onlineConsultation_data});

    if(onlineConsultation_data.length === 0 || null ){
      notification["info"]({
        message: "This doctor is unavailable for online consultation",
      });
    }
  }
  
 handleOnlineConsultationCard=(item:any,index:any)=>{
  this.setState({active_consultation_type:index})
  this.setState({online_consultation_choose:true})
  this.setState({consultation_type:item.attributes?.consultation_type})
  this.setState({consultation_fees:item.attributes?.price})
  this.getVirtualTimeSlot()
}

habdleContinueButton=()=>{
  this.setState({isLoading:true})
this.bookingAppointmentSlot();
}

handleTimeSlotSection=(item:any)=>{
  this.setState({selectedTimeSlotMorning:""});
  const {activeTab} = this.state
  this.setState({active_time_slot_section:item},()=>{
   activeTab  === "Virtual" ? this.getVirtualTimeSlot(): this.getInClinicTimeSlot("In - Clinic")
  })  
}

onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
  const {activeTab} = this.state
  this.setState({selected_date:dateString},()=>{
    activeTab  === "Virtual" ? this.getVirtualTimeSlot(): this.getInClinicTimeSlot("In - Clinic")
  })
};

disabledPastDate = (current: any) => {
  return current && current <= moment().startOf("day");
}

handleSetTimeSlot=(item:any,index:any)=>{
  this.setState({isTimeSlotSelected:true});
  this.setState({selectedTimeSlotMorning:index});
  this.setState({time_slot:item});
}

 handleAlignment = (event:any, newAlignment:any) => {
  this.setState({activeTab:newAlignment})
  this.isDoctorOnlineAvailable(newAlignment)
  if(newAlignment === "In - Clinic"){
    this.setState({online_consultation_choose:false});
    if(this.state.doctorDetails && this.state.doctorDetails.attributes?.inclinic_consultation.length>0 ){
      this.getInClinicTimeSlot(newAlignment)
    }else{
          notification["info"]({
      message: "This doctor is unavailable for in-clinic consultation",
    });
    } 
  }
};

isDoctorOnlineAvailable=(newAlignment:string)=>{
  if(  newAlignment ==="Virtual"){
     if(this.state.doctorDetails && this.state.doctorDetails.attributes?.online_consultation.length ===0 || null){
      notification["info"]({
        message: "This doctor is unavailable for online consultation",
      })
    }
  }
}
getVirtualTimeSlot=()=>{

    const header = {

      "Content-Type": configJSON.loginApiContentType,
      "token":localStorage.getItem("token")
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVirtualTimeSlotApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_appointment_management/time_slot?[data][attributes][appointment_date]=${this.state.selected_date}&[data][attributes][mode_type]=${this.state.activeTab}&[data][attributes][doctor_id]=${localStorage.getItem("doctor_id")}&[data][attributes][time_slot]=${this.state.active_time_slot_section}&[data][attributes][user_type]=patient`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTimeSlotAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;


}


getInClinicTimeSlot=(activeTimeSlot:string)=>{
  const header = {

    "Content-Type": configJSON.getProfileDetailsContentType,
    "token":localStorage.getItem("token")
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getInClinicTimeSlotApiCalledId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/bx_block_appointment_management/time_slot?[data][attributes][appointment_date]=${this.state.selected_date}&[data][attributes][mode_type]=${activeTimeSlot}&[data][attributes][doctor_id]=${localStorage.getItem("doctor_id")}&[data][attributes][time_slot]=${this.state.active_time_slot_section}&[data][attributes][user_type]=patient`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getTimeSlotAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;


}

bookingAppointmentSlot=()=>{

  const header = {
    "Content-Type": configJSON.appointmentApiContentType,
    "token":localStorage.getItem("token")
  };

  let inClinicappointmentBookingData={
    "data":  {
            "attributes": {
                "appointment_date":this.state.selected_date,
                "doctor_id":localStorage.getItem("doctor_id"),
                "consultation_category":"In Clinic Consultation",
                "pick_time":this.state.active_time_slot_section,
                "time_slot":this.state.time_slot,
                "mode_type": "In - Clinic",
                "promo_code":"discount",
                
                   } 
    }    
}


let virtualAppointmentBookingData={
    "data":  {
            "attributes": {
                "appointment_date":this.state.selected_date,
                "doctor_id":localStorage.getItem("doctor_id"),
                "consultation_category":"Online Consultation",
                "pick_time":this.state.active_time_slot_section,
                "time_slot":this.state.time_slot,
                "consultation_type":this.state.consultation_type,
                "mode_type": "Virtual",
                "promo_code":"discount"
                   } 
       }   
}

 
  let appointmentData = this.state.activeTab === "Virtual" ? virtualAppointmentBookingData : inClinicappointmentBookingData



  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.bookingAppointmentSlotApiCalledId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_appointment_management/create_appointment`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(appointmentData)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.bookAppointmentApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;

}



getOnlineConsultationData=  ()=>{
  const header = {

    "Content-Type": configJSON.loginApiContentType,
    "token": this.state.token
  };


  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getOnlineConsultationDataApiCalledId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_appointment_management/get_consultation_fees?doctor_id=${this.state.doctorId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getTimeSlotAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;


}

getClinicDetailsData=  ()=>{
  const header = {
    "Content-Type": configJSON.loginApiContentType,
    "token":localStorage.getItem("token")
  };

  const doctorId = localStorage.getItem("doctor_id")
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.clinicDetailsApiCalledId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getClinicDetailsAPIEndpoint}?id=${doctorId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getTimeSlotAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;


}
scrollToTop=()=>{
  this.myRef.current.scroll(0,0);
  window.scroll(0,0);
  
}

showDetailsHandler = () => {
  this.setState({ showPopup: !this.state.showPopup });
} 


handleLogout = () => {
  localStorage.clear();
  notification["success"]({
      message: "Logout succesfully",
    });
}


handleViewClinicDetails=()=>{
  this.getClinicDetailsData();
  this.setState({showClinicDetails:!this.state.showClinicDetails})
 }

 handleHideClinicDetails=()=>{
  this.setState({showClinicDetails:!this.state.showClinicDetails});
 }


async componentDidMount(){

  const rootElement = document.getElementById('root');
if (rootElement) {
  this.setState({ contentHeight: rootElement.offsetHeight+33});
}
  this.setState({
    first_name:this.props.location.state.selectedDoctorData.attributes?.first_name,
    last_name:this.props.location.state.selectedDoctorData.attributes?.last_name,
    full_name:this.props.location.state.selectedDoctorData.attributes?.full_name,
    image:this.props.location.state.selectedDoctorData.attributes?.profile_image,
    specialization:this.props.location.state.selectedDoctorData.attributes?.specialization,
    qualification:this.props.location.state.selectedDoctorData.attributes?.qualification,
    doctorExp:this.props.location.state.selectedDoctorData.attributes?.experience,
    consultation_fees: this.props.location.state.selectedDoctorData.attributes?.inclinic_consultation?.[0]?.price ?? this.props.location.state.selectedDoctorData.attributes?.fees,
    about:this.props.location.state.selectedDoctorData.attributes?.about,
    rating_average:this.props.location.state.selectedDoctorData.attributes?.rating_average,
    reviews_count:this.props.location.state.selectedDoctorData.attributes?.reviews_count
  })
this.setState({
  doctorLanguage:this.props.location.state.selectedDoctorData.attributes?.language
})
let doctorID:any = await localStorage.getItem("doctor_id")
let token:any = await localStorage.getItem("token")
 this.setState({doctorId:doctorID,token:token})
 this.getOnlineConsultationData();
 this.get_doctor_data(doctorID);
  
this.scrollToTop();

if(this.props?.location?.state?.navigateList){
  this.setState({appointmentNavigationList: this.props?.location?.state?.navigateList})
  localStorage.setItem("breadCrumbs",JSON.stringify(this.props?.location?.state?.navigateList))
}else{
  this.setState({appointmentNavigationList:localStorage.getItem("breadCrumbs")})
}
}
handleDoctorKnownLanguage=(knownLanguageArray:[])=>{
  const knownLanguage = knownLanguageArray.filter((language)=>{
    return  language != ""
  } );
  return knownLanguage;
}

get_doctor_data=  (doctorId:string)=>{
  const header = {
    "Content-Type": configJSON.loginApiContentType,
    "token":localStorage.getItem("token")
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getDoctorDetailsApiCalledId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getDoctorByIdApiEndPoint}?id=${doctorId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getTimeSlotAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  this.setState({isLoading:true});
  return true;


}
  // Customizable Area Start
  // Customizable Area End
}
