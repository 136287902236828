import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import * as H from "history";
import { message as MESSAGE, notification } from "antd";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    history: H.History;
    location: any;
    // Customizable Area End
}

// Customizable Area Start
export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
}

export interface ValidResponseType {
    message: string;
    data: object;
    scheduled: object;
}

export interface InvalidResponseType {
    errors: Array<string>;
}

export interface InvoiceDataTypes {
    data: {
        id: string;
        attributes: {
            appointment_date: string;
            appointment_type: string;
            consultation_category: string;
            consultation_type: string;
            discount_price: number;
            doctor_category: string
            doctor_experience: string;
            doctor_full_name: string;
            doctor_id: number;
            doctor_phone_number: string;
            doctor_specialization: string;
            fees: number;
            health_id: string;
            mode_type: string;
            original_price: string;
            patient_address: string;
            patient_full_name: string;
            patient_gender: string;
            patient_id: number;
            patient_year: string;
            time_slot: string;
        }
    }
}

export interface DoctorDetailsType {
    data: DoctorDetailsData;
}
export interface DoctorDetailsData {
    id: string;
    type: string;
    attributes: {
        full_name: string;
        medical_representative_name: string;
        representative_contact_no: string;
        profile_image: string;
    }
}
// Customizable Area End

interface S {
    // Customizable Area Start
    isInvoiceOpen: boolean;
    showPopup: boolean;
    doctorProfileData: object;
    appointmentId: number;
    invoiceDetailsData: InvoiceDataTypes;
    invoicePdfDetailsData: object;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class InvoiceDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getInvoiceDetailsApiCallId: string = "";
    getInvoicePdfDetailsApiCallId: string = "";
    getProfileDetailsCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isInvoiceOpen: false,
            showPopup: false,
            doctorProfileData: {},
            appointmentId: 0,
            invoiceDetailsData: {
                data: {
                    id: "",
                    attributes: {
                        appointment_date: "",
                        appointment_type: "",
                        consultation_category: "",
                        consultation_type: "",
                        discount_price: 0,
                        doctor_category: "",
                        doctor_experience: "",
                        doctor_full_name: "",
                        doctor_id: 0,
                        doctor_phone_number: "",
                        doctor_specialization: "",
                        fees: 0,
                        health_id: "",
                        mode_type: "",
                        original_price: "",
                        patient_address: "",
                        patient_full_name: "",
                        patient_gender: "",
                        patient_id: 0,
                        patient_year: "",
                        time_slot: ""
                    }
                }
            },
            invoicePdfDetailsData: {}
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getDoctorProfileDetailsfunction();
        this.setState({
            appointmentId: this.props.location.state.appointments_id
        }, () => {
            this.getInvoiceDetailsfunction();
        }
        );
    }

    handelDoctoPopUp = () => {
        this.setState({ showPopup: !this.state.showPopup });
    };

    handleUserLogout = () => {
        localStorage.clear();
        notification["success"]({
            message: "Logout successfully",
        });
        this.props.history.push("/");
    };

    handleDocDetailsPage = () => {
        this.props.history.push("/doctorprofiledetails");
    };

    handleEditDocProfile = () => {
        this.props.history.push("/editdoctordetails")
    };

    onClickHandler = () => {
        this.setState({
            isInvoiceOpen: true
        });
    }

    handlePopupClose = () => {
        this.setState({
            showPopup: false
        });
    };

    invoiceApiCall = (data: APIPayloadType) => {
        const token = localStorage.getItem("token");
        let { contentType, method, endPoint, body } = data;
        const header = {
            "Content-Type": contentType,
            token
        };
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson.status === 500) {
                MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
                return;
            }
            if (this.isValidResponse(responseJson)) {
                this.apiInvoiceSuccessCallBacks(apiRequestCallId, responseJson);
            } else if (errorReponse) {
                notification["error"]({
                    message: "Internal Server Error.",
                });
            }
        }
    }

    isValidResponse = (responseJson: ValidResponseType) => {
        return (responseJson && responseJson.data);
    };

    apiInvoiceSuccessCallBacks = (apiRequestCallId: string, responseJson: ValidResponseType & InvoiceDataTypes & DoctorDetailsType) => {
        if (apiRequestCallId === this.getInvoiceDetailsApiCallId) {
            this.getInvoiceDetailsSuccessCallBack(responseJson);
        } 
        if (apiRequestCallId === this.getInvoicePdfDetailsApiCallId) {
            this.getInvoicePdfDetailsSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getProfileDetailsCallId) {
            this.getDoctorProfileDetailsSuccessCallBack(responseJson);
        }
    };

    getInvoiceDetailsfunction = async () => {
        this.getInvoiceDetailsApiCallId = this.invoiceApiCall({
            contentType: configJSON.getInvoiceDetailsContentType,
            method: configJSON.getInvoiceDetailsMethod,
            endPoint: `${configJSON.getInvoiceDetailsEndPoint}?appointment_id=${this.state.appointmentId}`
        });
    };

    getInvoiceDetailsSuccessCallBack = (response: InvoiceDataTypes) => {
        this.setState({
            invoiceDetailsData: response
        });
    };  

    getInvoicePdfDetailsfunction = async () => {
        this.getInvoicePdfDetailsApiCallId = this.invoiceApiCall({
            contentType: configJSON.getInvoiceDetailsContentType,
            method: configJSON.getInvoiceDetailsMethod,
            endPoint: `${configJSON.getInvoicePdfDetailsEndPoint}?appointment_id=${this.state.appointmentId}`
        });
    };

    getInvoicePdfDetailsSuccessCallBack = (response: object) => {
        notification["success"]({
            message: "Invoice downloaded successfully.",
        });
    };

    getDoctorProfileDetailsfunction = async () => {
        this.getProfileDetailsCallId = this.invoiceApiCall({
            contentType: configJSON.getProfileDetailsContentType,
            method: configJSON.getProfileDetailsMethod,
            endPoint: configJSON.getProfileDetailsEndPoint
        });
    };

    getDoctorProfileDetailsSuccessCallBack = (response: DoctorDetailsType) => {
        this.setState({
            doctorProfileData: response
        });
    };
    // Customizable Area End
}
