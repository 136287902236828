import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import * as H from "history";
import { ChangeEvent } from "react";
// Customizable Area End

// Customizable Area Start

export interface InvalidResponseType {
  errors: [
    {
      consultation_category: string;
    }
  ]
}

export interface ValidResponseType {
  message: object;
  data: object;
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export interface DoctorDetailsType {
  data: DoctorDetailsData;
}

export interface DoctorDetailsData {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    medical_representative_name: string;
    representative_contact_no: string;
    profile_image: string;
  }
}

export interface CreateConsulationFeesData {
  data: Array<SubConsultationdata>
}

export interface SubConsultationdata {
  id: string,
  type: string,
  attributes: {
    doctor_id: number,
    consultation_category: string,
    consultation_type: string,
    description: string,
    price: string,
    icon: string
  }
}

export interface PackageData {
  data: Array<PackageType>
}

export interface PackageType {
  id: string;
  attributes: {
    name: string;
    no_of_tests: number;
    description: string;
    duration: string;
    consultation_fees: number;
    sample_requirement: string;
    doctor_id: number;
  }
}
export interface ConsultationData {
  data:
  [
    {
      id: string,
      type: string,
      attributes: {
        doctor_id: number,
        consultation_category: string,
        consultation_type: string,
        description: string,
        price: string,
        icon: string
      }
    }
  ]
}

export interface ConsultationEditType {
  id: string,
  type: string,
  attributes: {
    doctor_id: number,
    consultation_category: string,
    consultation_type: string,
    description: string,
    price: string,
    icon: string
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  history: H.History;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showPopup: boolean;
  doctorProfileData: DoctorDetailsType;
  sample: string | unknown;
  selectValue: Array<string>;
  isAddPackage: boolean;
  packagesData: PackageData;
  packageId: string;
  consultationData: ConsultationData;
  doctorId: string | null;
  packageName: string;
  packageErr: string;
  testCount: string;
  testCountErr: string;
  testConsultationFees: string;
  testConsultationFeesErr: string;
  consultationFee: string;
  consultationFeeErr: string;
  descriptions: string;
  descriptionsErr: string;
  description: string;
  descriptionErr: string;
  duration: string | unknown;
  durationErr: string;
  sampleRequireErr: string;
  durationValue: Array<string>;
  isDeletePackageModal: boolean;
  isAddConsultation: boolean;
  consultationCategory: Array<string>;
  consultationType: Array<string>;
  categoryData: string | unknown;
  categoryDataErr: string;
  categoryType: string | unknown;
  categoryTypeErr: string;
  isDescription: boolean;
  isEditConsultation: boolean;
  editCategoryData: string | unknown;
  editCategoryDataErr: string;
  editCategoryTypeErr: string;
  editCategoryType: string | unknown;
  editConsultationFee: string;
  editConsultationFeeErr: string;
  editDescriptions: string;
  editDescriptionsErr: string;
  editConsultationId: string;
  isLoader: boolean;
  contentHeight: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FeespackagemanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileDetailsCallId: string = "";
  getPackageListApiCallId: string = "";
  deletePackageApiCallId: string = "";
  getConsultationApiCallId: string = "";
  postAddPackagesApiCallId: string = "";
  editPackageApiCallId: string = "";
  feesPackageCallId: string = "";
  putConsultationFeesApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showPopup: false,
      doctorProfileData: {
        data: {
          id: "",
          type: "",
          attributes: {
            full_name: "",
            medical_representative_name: "",
            representative_contact_no: "",
            profile_image: ""
          }
        }
      },
      sample: "",
      selectValue: ["Blood", "Serum", "Plasma", "Stool"],
      isAddPackage: false,
      packagesData: {
        data: [
          {
            id: "",
            attributes: {
              name: "",
              no_of_tests: 0,
              description: "",
              duration: "",
              consultation_fees: 0,
              sample_requirement: "",
              doctor_id: 0
            }
          }
        ]
      },
      packageId: "",
      consultationData: {
        data: [
          {
            id: "",
            type: "",
            attributes: {
              doctor_id: 0,
              consultation_category: "",
              consultation_type: "",
              description: "",
              price: "",
              icon: ""
            }
          }
        ]
      },
      doctorId: "",
      packageName: "",
      packageErr: "",
      testCount: "",
      testCountErr: "",
      testConsultationFees: "",
      testConsultationFeesErr: "",
      description: "",
      descriptionErr: "",
      duration: "",
      durationErr: "",
      sampleRequireErr: "",
      durationValue: ["15 minutes", "30 minutes", "45 minutes", "1 hour"],
      isDeletePackageModal: false,
      isAddConsultation: false,
      consultationCategory: ["Online Consultation", "In Clinic Consultation"],
      consultationType: ["Text Consultation", "Phone Consultation", "Video Consultation"],
      categoryData: "",
      categoryDataErr: "",
      categoryTypeErr: "",
      categoryType: "",
      consultationFee: "",
      consultationFeeErr: "",
      descriptions: "",
      descriptionsErr: "",
      isDescription: false,
      isEditConsultation: false,
      editCategoryData: "",
      editCategoryDataErr: "",
      editCategoryTypeErr: "",
      editCategoryType: "",
      editConsultationFee: "",
      editConsultationFeeErr: "",
      editDescriptions: "",
      editDescriptionsErr: "",
      editConsultationId: "",
      isLoader: false,
      contentHeight: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.scroll({
      top:0,
      left:0
    });
    let getId = localStorage.getItem("doctorId")
    this.getProfilefunction();
    this.getPackageListFunction();
    this.setState({ doctorId: getId }, () => {
      this.getConsultationFunction()
    });
    const rootElement = document.getElementById('root');
    if (rootElement) {
      this.setState({ contentHeight: rootElement.offsetHeight+33});
    }
  }

  feeApiCall = (data: APIPayloadType) => {
    const token = localStorage.getItem("token");
    let { contentType, method, endPoint, body, type = "" } = data;
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    const header = {
      "Content-Type": contentType,
      token
    };
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? JSON.stringify(body) : body
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  async receive(from: string, message: Message) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (this.isFeeValidRes(responseJson)) {
        this.feeApiSuccessCall(apiRequestCallId, responseJson);
      }
    }
  }

  isFeeValidRes = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson.data) || (responseJson && responseJson.message)
  };

  feeApiSuccessCall = (apiRequestCallId: string, responseJson: DoctorDetailsType & PackageData & object & ConsultationData & CreateConsulationFeesData) => {
    if (apiRequestCallId === this.getProfileDetailsCallId) {
      this.getProfileSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getPackageListApiCallId) {
      this.getPackageListFunctionSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.deletePackageApiCallId) {
      this.deletePackageListFunctionSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getConsultationApiCallId) {
      this.getConsultationFunctionSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postAddPackagesApiCallId) {
      this.postAddPackageSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.feesPackageCallId) {
      this.feesCreateFunctionSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.putConsultationFeesApiCallId) {
      this.putConsultationFeesSuccessCallBack(responseJson);
    }
  };

  handelShowDoctoPopUp = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleDocDetailsPage = () => {
    this.props.history.push("/doctorprofiledetails");
  };

  handleShowPopupClose = () => {
    this.setState({
      showPopup: false
    });
  };

  handleEditDocProfile = () => {
    this.props.history.push("/editdoctordetails")
  };

  getProfilefunction = async () => {
    this.getProfileDetailsCallId = this.feeApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getDoctorProfileDetailsAPiEndPoint
    });
    this.setState({isLoader: true});
  };

  getProfileSuccessCallBack = (response: DoctorDetailsType) => {
    this.setState({
      doctorProfileData: response,
      isLoader: false
    });
  };

  handleDoctorLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout successfully",
    });
    this.props.history.push("/");
  };

  handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    this.setState({ sample: event.target.value }, () => {
      if (this.state.sample !== "") {
        return this.setState({
          sampleRequireErr: ""
        });
      }
    });
  };

  handleCloseModal = () => {
    this.setState({
      isAddPackage: false,
      packageName: "",
      testCount: "",
      description: "",
      testConsultationFees: "",
      duration: "",
      sample: "",
      packageErr: "",
      testCountErr: "",
      testConsultationFeesErr: "",
      descriptionErr: "",
      durationErr: ""
    });
  };

  handleOpenPackage = () => {
    this.setState({ isAddPackage: true });
  };

  getPackageListFunction = async () => {
    this.getPackageListApiCallId = this.feeApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getPackagesApiEndPoint
    });
  };

  getPackageListFunctionSuccessCallBack = (response: PackageData) => {
    this.setState({
      packagesData: response
    });
  };

  handleDeletePackage = (deleteId: string) => {
    this.setState({
      packageId: deleteId,
      isDeletePackageModal: true
    });
  };

  deletePackageFunction = async () => {
    this.deletePackageApiCallId = this.feeApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.putDeleteImageApiMethod,
      endPoint: `${configJSON.deletePackageApiEndPoint}?id=${this.state.packageId}`
    });
    this.setState({
      isLoader: true
    });
  };

  deletePackageListFunctionSuccessCallBack = (response: PackageData) => {
    notification["success"]({
      message: "Package deleted successfully",
    });
    this.setState({
      packageId: "",
      isDeletePackageModal: false,
      isLoader: false
    });
    this.getPackageListFunction();
  };

  getConsultationFunction = async () => {
    this.getConsultationApiCallId = this.feeApiCall({
      contentType: configJSON.exampleApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getConsultationApiEndPoint}?doctor_id=${this.state.doctorId}`
    });
  };

  getConsultationFunctionSuccessCallBack = (response: ConsultationData) => {
    this.setState({
      consultationData: response
    });
  };

  postAddPackageFunction = async () => {
    const nameRegex = /^(?![0\s#])[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/;
    const regex = /^[1-9]\d*$/;
    const feesRegex = /^[1-9]\d*$/;
    const descriptionRegex = /^(?![0\s#])[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/
    if (!nameRegex.test(this.state.packageName)) {
      return this.setState({
        packageErr: "Please enter a valid package name"
      });
    }
    if (!regex.test(this.state.testCount)) {
      return this.setState({
        testCountErr: "Please enter a valid test count"
      });
    }
    if (!feesRegex.test(this.state.testConsultationFees)) {
      return this.setState({
        testConsultationFeesErr: "Please enter a valid consultation fees"
      });
    }
    if (!descriptionRegex.test(this.state.description)) {
      return this.setState({
        descriptionErr: "Please enter a valid description"
      });
    }
    if (this.state.duration == "") {
      return this.setState({
        durationErr: "Please select duration"
      });
    }
    if (this.state.sample == "") {
      return this.setState({
        sampleRequireErr: "Please select sample requirement"
      });
    }
    let apiBody = {
      name: this.state.packageName,
      no_of_tests: this.state.testCount,
      description: this.state.description,
      consultation_fees: this.state.testConsultationFees,
      duration: this.state.duration,
      sample_requirement: this.state.sample
    }
    this.postAddPackagesApiCallId = this.feeApiCall({
      method: configJSON.postDoctorRegisterApiMethod,
      contentType: configJSON.exampleApiContentType,
      endPoint: configJSON.addPackagesApiEndPoint,
      body: apiBody,
    });
    this.setState({isLoader: true});
  };

  postAddPackageSuccessCallBack = (response: object) => {
    notification["success"]({
      message: "Package created successfully",
    });
    this.setState({
      isAddPackage: false,
      isLoader: false,
      packageName: "",
      testCount: "",
      description: "",
      testConsultationFees: "",
      duration: "",
      sample: "",
      packageErr: "",
      testCountErr: "",
      testConsultationFeesErr: "",
      descriptionErr: "",
      durationErr: "",
      sampleRequireErr: "",
      isDescription: false
    });
    this.getPackageListFunction();
  };

  handlePackageName = (event: { target: { value: string } }) => {
    let eventValue = event.target.value;
    const nameRegex = /^(?![0\s#])[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/;
    this.setState({
      packageName: event.target.value,
    }, () => {
      if (nameRegex.test(eventValue)) {
        this.setState({
          packageErr: "",
        });
      } else {
        this.setState({
          packageErr: eventValue ? "Please enter a valid package name" : "This field is required.",
        });
      }
    });
  };

  handleTests = (event: { target: { value: string } }) => {
    const regex = /^[1-9]\d*$/
    let eventInput = event.target.value;
    this.setState({
      testCount: event.target.value,
    }, () => {
      if (regex.test(eventInput)) {
        this.setState({
          testCountErr: "",
        });
      } else {
        this.setState({
          testCountErr: eventInput ? "Please enter a valid test count" : "This field is required.",
        });
      }
    });
  };

  consultationFeesHandelChange = (event: { target: { value: string } }) => {
    const regex = /^[1-9]\d*$/
    let consultationFees = event.target.value;
    this.setState({
      testConsultationFees: event.target.value,
    }, () => {
      if (regex.test(consultationFees)) {
        this.setState({
          testConsultationFeesErr: "",
        });
      } else {
        this.setState({
          testConsultationFeesErr: consultationFees ? "Please enter a valid consultation fees" : "This field is required.",
        });
      }
    });
  };

  descriptionHandelChange = (event: { target: { value: string } }) => {
    const regex = /^(?![0\s#])[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/
    let descriptionValue = event.target.value;
    this.setState({
      description: event.target.value,
    }, () => {
      if (regex.test(descriptionValue)) {
        this.setState({
          descriptionErr: "",
        });
      } else {
        this.setState({
          descriptionErr: descriptionValue ? "Please enter a valid description" : "This field is required.",
        });
      }
    });
  };

  durationHandelChange = (event: ChangeEvent<{ value: unknown }>) => {
    this.setState({ duration: event.target.value }, () => {
      if (this.state.duration !== "") {
        return this.setState({
          durationErr: ""
        });
      }
    });
  };

  handleEditPackage = (item: PackageType) => {
    this.props.history.push({ pathname: "/packagedetails", state: { packageDetails: item } })
  };

  closeDeleteModal = () => {
    this.setState({
      isDeletePackageModal: false,
      packageId: ""
    });
  };

  handleGoBack = () => {
    this.props.history.goBack();
  };

  handleConsultationModal = () => {
    this.setState({
      isAddConsultation: true
    });
  };

  handleConsultationCategory = (event: ChangeEvent<{ value: string | unknown }>) => {
    const inputValue = (event.target).value;
    this.setState({ categoryData: inputValue }, () => {
      if (this.state.categoryData == "") {
        return this.setState({
          categoryDataErr: "Please select consultation category"
        });
      } else {
        return this.setState({
          categoryDataErr: ""
        });
      }
    });
  };

  handleConsultationType = (event: ChangeEvent<{ value: string | unknown }>) => {
    const inputValue = (event.target).value;
    this.setState({ categoryType: inputValue }, () => {
      if (this.state.categoryType == "") {
        return this.setState({
          categoryTypeErr: "Please select consultation type"
        });
      } else {
        return this.setState({
          categoryTypeErr: ""
        });
      }
    });
  };

  parseConverter = (data: string | unknown) => {
    let value: string | unknown = data;
    let parseValue = JSON.stringify(value);
    return JSON.parse(parseValue);
  };

  handleTypeErr = () => {
    if (!this.state.categoryType) {
      return this.setState({
        categoryTypeErr: "Please select consultation type"
      });
    }
  };

  feesCreateFunction = async () => {
    const feesRegex = /^[1-9]\d{0,4}$/;
    
    if (!this.state.categoryData) {
      return this.setState({
        categoryDataErr: "Please select consultation category"
      });
    }
    if(this.state.categoryData === "Online Consultation") {
      this.handleTypeErr();
    }
    if (!feesRegex.test(this.state.consultationFee)) {
      return this.setState({
        consultationFeeErr : "Please enter a valid price"
      });
    }
    if(this.state.categoryData === "Online Consultation" && this.state.isDescription) {
      const descriptionRegex = /^(?![0\s#])[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/;
      if (!descriptionRegex.test(this.state.descriptions)) {
        return this.setState({
          descriptionsErr: "Please enter a valid description"
        });
      }
    }
   
    let formdata = new FormData();
    formdata.append("data[consultation_category]", this.parseConverter(this.state.categoryData))
    formdata.append("data[consultation_type]", this.parseConverter(this.state.categoryType))
    formdata.append("data[price]", this.state.consultationFee)
    formdata.append("data[description]", this.state.descriptions)
    this.feesPackageCallId = this.feeApiCall({
      method: configJSON.postDoctorRegisterApiMethod,
      endPoint: configJSON.feesPackageAPiEndPoint,
      body: formdata,
      type: "formData"
    });
    this.setState({isLoader: true});
  };

  feesCreateFunctionSuccessCallBack = (response: object) => {
    notification["success"]({
      message: "Doctor Consultation created successfully",
    });
    this.setState({
      isAddConsultation: false,
      isLoader: false,
      consultationFee: "",
      descriptions: "",
      categoryData: "",
      categoryType: "",
      consultationFeeErr: "",
      categoryDataErr: "",
      categoryTypeErr: "",
      descriptionsErr: "",
      isDescription: false
    }); 
    this.getConsultationFunction();
  };

  handleConsultationPrice = (event: { target: { value: string } }) => {
    const regex = /^[1-9]\d{0,4}$/;
    let consultationFees = event.target.value;
    this.setState({
      consultationFee: event.target.value,
    }, () => {
      if (regex.test(consultationFees)) {
        this.setState({
          consultationFeeErr: "",
        });
      } else {
        this.setState({
          consultationFeeErr: consultationFees ? "Please enter a valid price" : "This field is required",
        });
      }
    });
  };

  handleDescription = (event: { target: { value: string } }) => {
    const regex = /^(?![0\s#])[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/
    let descriptionValue = event.target.value;
    this.setState({
      descriptions: event.target.value,
      isDescription: true
    }, () => {
      if (regex.test(descriptionValue)) {
        this.setState({
          descriptionsErr: "",
        });
      } else {
        this.setState({
          descriptionsErr: descriptionValue ? "Please enter a valid description" : "This field is required",
        });
      }
    });
  };

  handleCancelConsultationModal = () => {
      this.setState({
        isAddConsultation: false,
        consultationFee: "",
        descriptions: "",
        categoryData: "",
        categoryDataErr: "",
        categoryTypeErr: "",
        descriptionsErr: "",
        categoryType: "",
        consultationFeeErr: "",
      }); 
  };

  handleEditConsultationModal = (item : ConsultationEditType) => {
   let price = item.attributes.price;
    let mainFees = price.split('.')[0]
    this.setState({
      isEditConsultation: true,
      editCategoryData: item.attributes.consultation_category,
      editCategoryType: item.attributes.consultation_type,
      editConsultationFee: mainFees,
      editDescriptions: item.attributes.description,
      editConsultationId: item.id
    });
  };

  handleEditTypeErr = () => {
    if (!this.state.editCategoryType) {
      return this.setState({
        editCategoryTypeErr: "Please select consultation type"
      });
    }
  };

  putDoctorConsultationFunction = async () => {
    const feesRegex = /^[1-9]\d{0,4}$/;
    
    if(this.state.editCategoryData === "Online Consultation") {
      this.handleEditTypeErr();
    }
    if (!feesRegex.test(this.state.editConsultationFee)) {
      return this.setState({
        editConsultationFeeErr : "Please enter a valid price"
      });
    }
    if(this.state.editCategoryData === "Online Consultation" && this.state.isDescription) {
      const descriptionRegex = /^(?![0\s#])[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/;
      if (!descriptionRegex.test(this.state.editDescriptions)) {
        return this.setState({
          editDescriptionsErr: "Please enter a valid description"
        });
      }
    }
    
    let formdata = new FormData();
    formdata.append("data[consultation_category]", this.parseConverter(this.state.editCategoryData))
    formdata.append("data[consultation_type]", this.parseConverter(this.state.editCategoryType))
    formdata.append("data[price]", this.state.editConsultationFee)
    formdata.append("data[description]", this.state.editDescriptions)
    formdata.append("data[consultation_id]", this.state.editConsultationId)
    this.putConsultationFeesApiCallId = this.feeApiCall({
      method: configJSON.postDoctorRegisterApiMethod,
      endPoint: configJSON.feesPackageEditAPiEndPoint,
      body: formdata,
      type: "formData"
    });
    this.setState({isLoader: true});
    };

  putConsultationFeesSuccessCallBack = (response: object) => {
    notification["success"]({
      message: "Consultation updated successfully",
    });
    this.setState({
      isEditConsultation: false,
      isLoader: false,
      editCategoryData: "",
      editCategoryDataErr: "",
      editCategoryTypeErr: "",
      editCategoryType: "",
      editConsultationFee: "",
      editConsultationFeeErr: "",
      editDescriptions: "",
      editDescriptionsErr: "",
      editConsultationId: "",
      isDescription: false
    });
    this.getConsultationFunction();
  };

  handleEditCancelConsultationModal = () => {
    this.setState({
      isEditConsultation: false,
      editCategoryData: "",
      editCategoryDataErr: "",
      editCategoryTypeErr: "",
      editCategoryType: "",
      editConsultationFee: "",
      editConsultationFeeErr: "",
      editDescriptions: "",
      editDescriptionsErr: "",
      editConsultationId: "",
      isDescription: false
    });
  };

  handleEditConsultationCategory = (event: ChangeEvent<{ value: string | unknown }>) => {
    const value = (event.target).value;
    this.setState({ editCategoryData: value }, () => {
      if (this.state.editCategoryData == "") {
        return this.setState({
          editCategoryDataErr: "Please select consultation category"
        });
      } else {
        return this.setState({
          editCategoryDataErr: ""
        });
      }
    });
  };

  handleEditConsultationType = (event: ChangeEvent<{ value: string | unknown }>) => {
    const value = (event.target).value;
    this.setState({ editCategoryType: value }, () => {
      if (this.state.editCategoryType == "") {
        return this.setState({
          editCategoryTypeErr: "Please select consultation type"
        });
      } else {
        return this.setState({
          editCategoryTypeErr: ""
        });
      }
    });
  };

  handleEditConsultationPrice = (event: { target: { value: string } }) => {
    const regex = /^[1-9]\d{0,4}$/;
    let fees = event.target.value;
    this.setState({
      editConsultationFee: event.target.value,
    }, () => {
      if (regex.test(fees)) {
        this.setState({
          editConsultationFeeErr: "",
        });
      } else {
        this.setState({
          editConsultationFeeErr: fees ? "Please enter a valid price" : "This field is required",
        });
      }
    });
  };

  handleEditDescription = (event: { target: { value: string } }) => {
    const regex = /^(?![0\s#])[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/
    let descriptionValue = event.target.value;
    this.setState({
      editDescriptions: event.target.value,
      isDescription: true
    }, () => {
      if (regex.test(descriptionValue)) {
        this.setState({
          editDescriptionsErr: "",
        });
      } else {
        this.setState({
          editDescriptionsErr: descriptionValue ? "Please enter a valid description" : "This field is required",
        });
      }
    });
  };

  customNavigation = (screenName: string) =>{
    this.props.history.push(screenName);
  };
  // Customizable Area End
}