import React from 'react'
// Customizable Area Start
import DoctorRegisterController from "./DoctorRegisterController.web";
import { Modal, Form, Input, Select, Row, Col, Button } from "antd";
import { uploadimg } from './assets'
import Loader from "../../../components/src/Loader.web";
// Customizable Area End
export class DoctorRegister extends DoctorRegisterController {
    // Customizable Area Start
    renderRegisterModal = () => {
        return (
            <Modal
                centered
                open={this.state.openRegModal}
                footer={null}
                width={920}
                closable={false}
            >
                <h2 className="modal-heading" data-test-id="textId">Register</h2>
                <Form
                    data-test-id="submitFormId"
                    labelCol={{ span: 24 }}
                    autoComplete="off"
                    wrapperCol={{ span: 24 }}
                    layout="horizontal"
                    onFinish={this.onFinishDoctorRegister}
                    fields={[
                        {
                            name: ["docName"],
                            value: this.state.doctorName,
                        },
                        {
                            name: ["phone"],
                            value: this.state.isPhone ? this.state.inputPhone : this.state.doc_phone,
                        },
                        {
                            name: ["email"],
                            value: this.state.isEmail ? this.state.inputEmail : this.state.doc_email,
                        },
                        {
                            name: ["doc_Sub_category"],
                            value: this.state.subCategory,
                        }
                    ]}
                >
                    <Row gutter={[12, 0]}>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Name"
                                name="docName"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your Name.",
                                    },
                                    {
                                        pattern: /^[a-zA-Z. ]{3,30}$/,
                                        message: "Please enter valid name",
                                    },
                                ]}
                                className="registerInput"
                            >
                                <Input name="docName" readOnly />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Phone"
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your Phone number",
                                    },
                                    {
                                        pattern: /^[6-9]\d{9}$/,
                                        message: "Please enter valid phone number",
                                    },
                                ]}
                                className="registerInput"
                            >
                                <Input name="phone" readOnly={Boolean(this.state.doc_phone)} onChange={this.handlePhoneChange} data-test-id="phoneId"/>
                            </Form.Item>
                            {this.state.phoneErr && <span style={{color: "red"}}>{this.state.phoneErr}</span>}
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your Email",
                                    },
                                    {
                                        pattern: /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        message: "Please enter valid email",
                                    },
                                ]}
                                className="registerInput"
                            >
                                <Input name="email" readOnly={Boolean(this.state.doc_email)} onChange={this.handleEmailChange} data-test-id="emailId"/>
                            </Form.Item>
                            {this.state.emailErr && <span style={{color: "red"}}>{this.state.emailErr}</span>}
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Registration No"
                                name="docRegistration"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter Registration Number.",
                                    },
                                    {
                                        pattern: /^[A-Za-z0-9]*$/,
                                        message: "Please enter valid registration number."
                                    },
                                    { max: 10, message: 'Maximum 10 characters are allowed' },
                                ]}
                                className="registerInput"
                            >
                                <Input data-test-id="registerNUmId" name="docRegistration" />
                            </Form.Item>
                            {this.state.registrationErr && <span style={{color: "red"}}>{this.state.registrationErr}</span>}
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Registration Council"
                                name="docRegistrationCouncil"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter Registration Council.",
                                    },
                                    {
                                        pattern: /^[A-Za-z\s]+$/,
                                        message: "Please enter valid registration council."
                                    },
                                    { max: 30, message: 'Maximum 30 characters are allowed' },
                                ]}
                                className="registerInput"
                            >
                                <Input name="docRegistrationCouncil" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Year"
                                name="docYear"
                                data-test-id="doc_year_validator"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter a year."
                                    },
                                    {
                                        pattern: /^(19\d{2}|20\d{2})$/,
                                        message: "Please enter a valid year (19xx or 20xx)."
                                    },
                                    {
                                        validator: (rule, value) => {
                                            const currentYear = new Date().getFullYear();
                                            const enteredYear = parseInt(value);
                                            if (enteredYear > currentYear) {
                                                return Promise.reject('Please enter a year that is not in the future.');
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ]}
                                className="registerInput"
                            >
                                <Input data-test-id="doc_year_id" name="docYear" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Doctor Category"
                                name="doc_category"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Doctor Category.",
                                    },
                                ]}
                                className="registerInput"
                            >
                                <Select data-test-id="doctorCategoryID" placeholder="Select Category" className="specializationSelect" onChange={this.handleCategoryChange}>
                                    {this.state.doctorCategoryData.map((item: any) => (
                                        <Select.Option value={JSON.stringify({categoryId:item.id,categoryName:item.name})} name="doc_category" key={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Doctor Sub Category"
                                name="doc_Sub_category"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select Doctor Sub Category.",
                                    },
                                ]}
                                className="registerInput"
                            >
                             {this.renderSelectContainer()}   
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item
                                label="City"
                                name="docCity"
                                rules={[{
                                    required: true,
                                    message: "Please enter City."
                                },
                                {
                                    pattern: /^[A-Za-z\s]+$/,
                                    message: "Pease enter valid Cityname"
                                }]}
                                className="registerInput"
                            >
                                <Input data-test-id="doc_city_id" name="docCity" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Medical Representative Name"
                                name="medicalName"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter Medical Representative Name.",
                                    },
                                    {
                                        pattern: /^[a-zA-Z. ]{3,30}$/,
                                        message: "Please enter valid representative name.",
                                    },
                                ]}
                                className="registerInput"
                            >
                                <Input name="medicalName" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item
                                label="Contact of Medical Representative"
                                name="medicalContact"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter Medical Representative contact number.",
                                    },
                                    {
                                        pattern: /^[6789]\d{9}$/,
                                        message: "Please enter valid contact number.",
                                    },
                                ]}
                                className="registerInput"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <Form.Item className="verification-button-container">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="registration-continue-button"
                                >
                                    Continue
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    };
    // Customizable Area End
    // Customizable Area Start
    renderVerifyModal = () => {
        return (
            <Modal
                style={{ top: 60 }}
                open={this.state.openVerifyModal}
                footer={null}
                width={980}
                closable={false}
            >
                <h2 className="modal-heading">Verification</h2>
                <p className="verification-desc-heading">
                    Please upload below documents to verify your identity:
                </p>
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    layout="horizontal"
                    className="verification-form"
                >
                    <Row gutter={[12, 0]}>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <label className="upload-label">Identity Details</label>
                            <div className="upload-container">
                                {this.state.identifyImg ? (
                                    this.state.identifyImg.length + " file uploaded "
                                ) : (
                                    <div className="upload-container">
                                        <input
                                            data-test-id="identifyId"
                                            type="file"
                                            name="image-upload"
                                            id="input"
                                            onChange={(e) => this.handleIdentifierImage("identifyImage", e)}
                                        />
                                        <label htmlFor='input' >
                                            <img src={uploadimg} className="plusIconStyle" />
                                        </label>
                                        <p className="upload-instruction">
                                            Browse to upload (Max 10MB)
                                        </p>
                                    </div>
                                )}
                            </div>
                            <p className="upload-info">
                                Upload any Govt. issued identify proof e.g Aadhar,PAN.etc
                            </p>
                            {this.state.identifyMaxSize ? (
                                <p className="upload-error">File size is greater than 10mb.</p>
                            ) : (
                                ""
                            )}
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item name="degreeDetails">
                                <label className="upload-label">Degree Details</label>
                                <div className="upload-container">
                                    {this.state.degreeImg ? (
                                        this.state.degreeImg.length + " file uploaded "
                                    ) : (
                                        <div className="upload-container">

                                            <input
                                                data-test-id="degreeId"
                                                type="file"
                                                name="degreeimgupload"
                                                id="degreeinput"
                                                style={{ display: "none" }}
                                                onChange={(e) => this.handleDrgreeImage("degreeImage", e)}
                                            />
                                            <label htmlFor='degreeinput' >
                                                <img src={uploadimg} className="plusIconStyle" />
                                            </label>

                                            <p className="upload-instruction">
                                                Browse to upload (Max 10MB)
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <p className="upload-info">Upload your degree certificate (pdf only)</p>
                                {this.state.degreeMaxSize ? (
                                    <p className="upload-error">
                                        File size is greater than 10mb.
                                    </p>
                                ) : (
                                    ""
                                )}
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item name="regisrationDetails">
                                <label className="upload-label">Registration Details</label>
                                <div className="upload-container">
                                    {this.state.registrationImg ? (
                                        this.state.registrationImg.length + " file uploaded "
                                    ) : (
                                        <div className="upload-container">

                                            <input
                                                data-test-id="registerId"
                                                type="file"
                                                name="registrationimgupload"
                                                id="registerinput"
                                                style={{ display: "none" }}
                                                onChange={(e) =>
                                                    this.handleRegistrationImage("registrationImage", e)
                                                }
                                            />
                                            <label htmlFor='registerinput' >
                                                <img src={uploadimg} className="plusIconStyle" />
                                            </label>

                                            <p className="upload-instruction">
                                                Browse to upload (Max 10MB)
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <p className="upload-info">
                                    Upload your Registration certificate (pdf only)
                                </p>
                                {this.state.registrationMaxSize ? (
                                    <p className="upload-error">
                                        File size is greater than 10mb.
                                    </p>
                                ) : (
                                    ""
                                )}
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item name="clinicRegDetails">
                                <label className="upload-label">
                                    Clinic Registration Details
                                </label>
                                <div className="upload-container">
                                    {this.state.clinicRegImg ? (
                                        this.state.clinicRegImg.length + " file uploaded "
                                    ) : (
                                        <div className="upload-container">

                                            <input
                                                data-test-id="clinicId"
                                                type="file"
                                                name="clinicuploadimg"
                                                id="clinicinput"
                                                style={{ display: "none" }}
                                                onChange={(e) =>
                                                    this.handleClinicImage("clinicRegImage", e)
                                                }
                                            />
                                            <label htmlFor='clinicinput' >
                                                <img src={uploadimg} className="plusIconStyle" />
                                            </label>

                                            <p className="upload-instruction">
                                                Browse to upload (Max 10MB)
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <p className="upload-info">
                                    Upload your clinic Registration certificate (pdf only)
                                </p>
                                {this.state.clinicRegMaxSize ? (
                                    <p className="upload-error">
                                        File size is greater than 10mb.
                                    </p>
                                ) : (
                                    ""
                                )}
                            </Form.Item>
                        </Col>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <div className="verification-button-container">
                                <Button type="primary" data-test-id="closeVerifyModalId" className="registration-skip-button" onClick={this.handleVerificationModal}>
                                    Skip for Now
                                </Button>
                                <Button type="primary" data-test-id="verifyDocId" className="registration-continue-button" onClick={this.postDoctorVerify}>
                                    Continue
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    };

    renderSelectContainer = () => {
        return (
            <Select data-test-id="doctorSubCategory" placeholder="Select Sub Category" className="specializationSelect" onChange={this.handleSubCategory}>
                {this.state.subCategoryData.data.map((item) => (
                    <Select.Option value={item.attributes.name} name="doc_Sub_category">
                        {item.attributes.name}
                    </Select.Option>
                ))}
            </Select>
        )
    };
    // Customizable Area End

    render() {
        return (
            <div>
                {this.renderRegisterModal()}
                {this.renderVerifyModal()}
                <Loader height={this.state.contentHeight} loading={this.state.isLoader} />
            </div>
        );
    }

}

// Customizable Area Start
export default DoctorRegister;
// Customizable Area End