import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { notification } from "antd";
export interface DoctorProfileDetailsType {
  data: ProfileData;
}

export interface ProfileData {
  id: string;
  type: string;
  attributes: {
    representative_contact_no: string;
    full_name: string;
    medical_representative_name: string;
    profile_image: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  patientNameErr: string;
  patientFullName: string;
  patientEmail: string;
  patientEmailErr: string;
  patientPhoneNum: string;
  patientPhoneNumErr: string;
  showPopup: boolean;
  isLogin: string | null;
  isPatientGoogleLogin: string | null;
  healthId: string | null;
  doctorName: string | null;
  doctorProfileData: DoctorProfileDetailsType;
  isKyc: string | null;
  isLoginUser: boolean;
  descriptionErr: string;
  description: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  postContactUsApiCallId: string = "";
  getDoctorProfileApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      patientNameErr: "",
      patientFullName: "",
      patientEmail: "",
      patientEmailErr: "",
      patientPhoneNum: "",
      patientPhoneNumErr: "",
      showPopup: false,
      isLogin: "",
      isPatientGoogleLogin: "",
      healthId: "",
      doctorName: "",
      doctorProfileData: {
        data: {
          id: "",
          type: "",
          attributes: {
            full_name: "",
            profile_image: "",
            medical_representative_name: "",
            representative_contact_no: "",
          }
        }
      },
      isKyc: "",
      isLoginUser: false,
      descriptionErr: "",
      description: ""
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getDoctorProfile();
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    const doctorName = localStorage.getItem("doctorName");
    const is_kyc = localStorage.getItem("is_kyc");
    const isDoctorGoogleLogin = localStorage.getItem("isDoctorGoogleLogin");
    const isPatientGoogleLogin = localStorage.getItem("isLogin");
    const healthId = localStorage.getItem("health_id");
    this.setState({
      isLogin: isDoctorGoogleLogin,
      isPatientGoogleLogin: isPatientGoogleLogin,
      healthId: healthId,
      doctorName: doctorName,
      isKyc: is_kyc
    });
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
     if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getContactUsList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.contactUsApiCallId) {
          this.setState({ contactUsList: responseJson.data });
        }
        if (apiRequestCallId === this.addContactApiCallId) {
          this.props.navigation.goBack();
        }
        this.apiSuccessCallBacks(apiRequestCallId , responseJson);
      } else if (
        responseJson &&
        responseJson.message &&
        apiRequestCallId === this.deleteContactApiCallId
      ) {
        this.setState({ isVisible: false });
        this.getContactUsList(this.state.token);
      } else if (responseJson && responseJson.errors) {
        if (responseJson.errors) {
          if (apiRequestCallId === this.addContactApiCallId) {
            responseJson.errors.forEach((error: any) => {
              if (error.contact) {
                this.showAlert(configJSON.errorTitle, error.contact.join("."));
              }
            });
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getDoctorProfileApiCallId) {
      this.setState({ doctorProfileData: responseJson });
    }
    if (apiRequestCallId === this.postContactUsApiCallId) {
      this.setState({ 
        patientFullName: "",
        patientEmail: "",
        patientPhoneNum: "",
        description: ""
      });
      notification["success"]({
        message: "Contact Us details submitted successfully.",
      });
    }
  };

  handleName = (event: { target: { value: string } }) => {
    const inputValue = event.target.value;
    const nameRegex = /^[A-Za-z ']+$/;
    this.setState({
      patientFullName: inputValue,
    }, () => {
      if (nameRegex.test(inputValue)) {
        this.setState({
          patientNameErr: '',
        });
      } else {
        this.setState({
          patientNameErr: inputValue ? 'Please enter a valid full name' : 'This field is required.',
        });
      }
    });
  };
  
  handleEmail = (event: { target: { value: string } }) => {
    const emailRegex = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emailInputValue = event.target.value;
    this.setState({
      patientEmail: emailInputValue,
    }, () => {
      if (emailRegex.test(emailInputValue)) {
        this.setState({
          patientEmailErr: '',
        });
      } else {
        this.setState({
          patientEmailErr: emailInputValue ? 'Please enter a valid email address' : 'This field is required.',
        });
      }
    });
  };
  
  handlePhone = (event: { target: { value: string } }) => {
    const numberRegex = /^[6-9]\d{9}$/;
    const patientInputValue = event.target.value;
    this.setState({
      patientPhoneNum: event.target.value
    }, () => {
      if (numberRegex.test(patientInputValue)) {
        this.setState({
          patientPhoneNumErr: "",
        });
      } else {
        this.setState({
          patientPhoneNumErr: patientInputValue ? "Please enter a valid contact number" : "This field is required.",
        });
      }
    })
  };

  handleDescription = (event: { target: { value: string } }) => {
    const value = event.target.value;
    const address = /^[0-9A-Za-z\s.,#-]{5,100}$/;
    this.setState({
      description: event.target.value,
    }, () => {
      if (address.test(value)) {
        this.setState({
          descriptionErr: "",
        });
      } else if (value.length > 100){
        this.setState({
          descriptionErr: "Please enter the description within character limit",
        });
      }
      else {
        this.setState({
          descriptionErr: value ? "Please enter a valid description" : "This field is required.",
        });
      }
    });
  };

  postContactUsApiFunction = () => {
    let error = this.handelValidation();
    if(error){
      return true
    }
    const token = localStorage.getItem("token");
      let apibody = {
        data: {
          name: this.state.patientFullName.trim(),
          email: this.state.patientEmail.trim(),
          phone_number: `91${this.state.patientPhoneNum.trim()}`,
          description: this.state.description.trim(),
        },
      };
      let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      let header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: token,
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(apibody)
      );
      this.postContactUsApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postConatctUsApiEndpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
  };

  handelShowDoctoPopUp = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleDoctorLogout = () => {
    localStorage.clear();
    notification["success"]({
      message: "Logout successfully",
    });
    this.props.history.push("/");
    this.setState({
      isLoginUser: false
  })
  };

  handleDocDetailsPage = () => {
    this.props.history.push("/doctorprofiledetails");
  };

  handleEditDocProfile = () => {
    this.props.history.push("/editdoctordetails")
  };

  handleShowPopupClose = () => {
    this.setState({
      showPopup: false
    });
  };

  getDoctorProfile = () => {
    const token = localStorage.getItem("token");
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    this.getDoctorProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDoctorProfileDetailsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handelValidation = () => {
    const nameRegex = /^[A-Za-z ']+$/;
    const numRegex = /^[6-9]\d{9}$/;
    const emailRegex = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const address = /^[0-9A-Za-z\s.,#-]{5,100}$/;
    if (!nameRegex.test(this.state.patientFullName)) {
       this.setState({
        patientNameErr: "Please enter a valid full name"
      });
      return true
    }
    if (!emailRegex.test(this.state.patientEmail)) {
     this.setState({
        patientEmailErr: "Please enter a valid email address"
      });
      return true
    }
    if (!numRegex.test(this.state.patientPhoneNum)) {
      this.setState({
         patientPhoneNumErr: "Please enter a valid contact number"
       });
       return true
     }
     if (!address.test(this.state.description)) {
      this.setState({
        descriptionErr: "Please enter a valid description"
       });
       return true
     }
     if(this.state.description.length > 100){
      this.setState({
        descriptionErr: "Please enter the description within character limit",
      });
      return true
     }
  };

  handleVerifyKyc = () => {
    notification["error"]({
      message: "Please upload or verify your documents to complete the KYC process",
    });
  };
  // Customizable Area End
}
