import React from "react";
// Customizable Area Start
// @ts-ignore
import {ToggleButton,ToggleButtonGroup} from '@material-ui/lab';
import {
Box,
  styled,
} from '@material-ui/core';
import {Row, Col, Typography,Avatar, Button,Segmented,Rate, Card,DatePicker,Form,Divider} from "antd"
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import { CertificationComponent } from "../../../components/src/CertificationComponent.web";
import {speechBubble,telephoneCall,video,language,health,sunrise,sunnyDay,moon,RightOutlined} from "./assets"
import {Link} from "react-router-dom"
import moment from "moment";
import FooterCommonComponent from "../../../components/src/FooterDoctor.web";
import { Default } from "../../splashscreen/src/assets";
import { Carousel } from 'react-responsive-carousel';
let config = require('../../../framework/src/config.js').baseURL;
import Text from "antd/lib/typography/Text";
import PatientProfilePopup from "../../../components/src/PatientProfilePopup.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import DoctorProfileController, {
  Props
} from "./DoctorProfileController.web";


export default class DoctorProfile extends DoctorProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  renderNavigationTitle = () => {
    let isAppointmentActiveOnDoctorProfile =
    window.location.pathname === "/doctorProfile";
  let appointmentClassName = isAppointmentActiveOnDoctorProfile ? "active" : "";
return (
    <>
        <div className="navigationContainer">
            <Link to="/patienthome" >Home</Link>
            <Link className={appointmentClassName} to="/patient-appointment-landingpage" >Appointment</Link>
            <Link to="/services" >Services</Link>
            <Link to="/message" >Message</Link>
        </div>
    </>
)
}

   options=
    [
      {
        label: (
          <div style={{ padding: 4 }} className="time-slot-container" >
            <Avatar src= {sunrise} alt="" className="time-slot-img" />
            <div>Morning</div>
          </div>
        ),
        value: 'Morning'
      },
      {
        label: (
          <div style={{ padding: 4 }} className="time-slot-container">
            <Avatar src= {sunnyDay} alt="" className="time-slot-img" />
            <div>Afternoon</div>
          </div>
        ),
        value: 'Afternoon'
      },
      {
        label: (
          <div style={{ padding: 4}} className="time-slot-container">
            <Avatar src= {moon} alt="" className="time-slot-img"/>
            <div>Evening</div>
          </div>
        ),
        value:'Evening'
      },

    ]

  testData = {
    'Text Consultation':speechBubble,
    'Phone Consultation':telephoneCall,
    'Video Consultation':video
  }

   renderOnlineConsultantComponent=()=>{

    return (
      <>
          <Row gutter={[24,24]} >
          <Col xl={24}>
            {
              this.state.onlineConsultationData.length >0 ?<Typography className="online_consultant_title" > Online Consultation</Typography> :""
            }
          </Col>
        </Row>

        <Row gutter={[24,4]} >
          {
            this.state.onlineConsultationData?.map((item:any,index:any)=>(
              <Col xs={24} sm={12} xl={8}  key={index} >
              <Card data-test-id="onlineConsultationID"  className={`doctor_consultant_card rs${this.state.active_consultation_type ===index && "200"}` } onClick={()=> this.handleOnlineConsultationCard(item,index)}   >
                <div className="doctor_consultant_card_icon" >
                {  //@ts-ignore
                  <img src={this.testData[`${item.attributes?.consultation_type}`]} alt="" className="online_consultation_img" />
                 } </div>
                <Typography className="doctor_consultant_title">{item.attributes?.consultation_type}</Typography>
  
                 <div className="text-fee-container" >
                 <Typography className="doctor_consultant_description" >
                  {item.attributes?.description.slice(0,100)}
                  </Typography>
  
                  <Typography className="doctor_consultant_fees" >
                  Rs {item.attributes?.price}
                  </Typography>
                 </div>
              </Card>
           
            </Col>
  
            ))
          }
        </Row>
      </>
    )
  }

  renderinClinicConsultantComponent=()=>{
    return (
      <>
          <Row gutter={[24,24]}>
          <Col xl={24}>
            <Typography className="online_consultant_title" >In-Clinic Consultation</Typography>
          </Col>
        </Row>
        <Row gutter={[24,8]}  >
          <Col xs={24} >
            <div className="doctor_consultant_wrapper"  >
              <Form>
                <Form.Item name="selectDate" >
                <Typography className="doctor_consultant_title"> Select Date</Typography>
                <DatePicker
                  defaultValue={moment()} 
                  name="selectDate"
                  data-test-id="selectDateId" 
                  disabledDate={this.disabledPastDate} 
                  className="select_calender customized"
                  onChange={this.onDateChange}
                   />
                </Form.Item> 
              </Form>
            </div>
         
          </Col>

          <Col xs={24}>
          <Typography className="doctor_consultant_title">Pick Time</Typography>
          <div className="doctor_consultant_wrapper" >
              <div>
              <Segmented onChange={(item)=>this.handleTimeSlotSection(item)} size="large" options={this.options} />
              </div>

              <div className="evening-time-slot-container" >

              {
                  this.state.active_time_slot_section === "Morning" && this.state.evening_time_slot?.length ? (
                    this.state.evening_time_slot.map((morningtimeSlot:any,index1:any)=>(
                      this.renderTimeSlotCard(morningtimeSlot,index1,"morningTimeSlotId")
                    ))
                  ):null
                } 

                {
                  this.state.active_time_slot_section === "Afternoon" && this.state.evening_time_slot?.length ? (
                    this.state.evening_time_slot.map((afternoontimeSlot:any,index2:any)=>(

                      this.renderTimeSlotCard(afternoontimeSlot,index2,"afternoonTimeSlotId")
                    ))
                  ):null
                } 


                {
                  this.state.active_time_slot_section === "Evening" && this.state.evening_time_slot?.length ? (
                    this.state.evening_time_slot.map((eveningtimeSlot:any,index4:any)=>(
                        this.renderTimeSlotCard(eveningtimeSlot,index4,"eveningTimeSlotId")
                    ))
                  ):null
                } 
              </div>
          </div> 
          </Col>
        </Row>
      </>

    )
  }

renderTimeSlotCard=(timeSlot:any,index:any,testId:string)=>{
  return (
    <Card 
       data-test-id={testId}
       onClick={()=> timeSlot.is_available ? this.handleSetTimeSlot(timeSlot.start_time,index) : {} }  
       className={timeSlot.is_available ? `time_slot_label ${this.state.selectedTimeSlotMorning === index && "moringActiveTimeSlot" }` :"time_slot_label_disable"  }>
          {timeSlot?.start_time}
    </Card> 

  )
}

renderDoctorExperiencee=(item:any)=>{
  return (
    <Typography className="doctorDescription" >{item.doctorExp ? item.doctorExp+ " Years Experience ": "" }</Typography>
  )
}

renderBreadCrumbs=()=>{
  return(
    <Box pb={3} pt={1} > 
    <div
      className="navigation-list-css-container2"
      style={{ paddingLeft: "5%" }}
    >
      <div key={"homeKey"}>
        <Text
          className="navigation-list-css2"
          style={{ color: "#498ECC" }}
          data-test-id="patientHomeID"
          onClick={() => this.props.history.push("/patienthome")}
        >
          Home
        </Text>
        <img className="rightArrowIcon" src={RightOutlined} alt=""/>
      </div>
      <div key={"ri425"}>
        <Text
          key={"ServicesKey"}
          style={{ color: "#498ECC" }}
          className="navigation-list-css"
          data-test-id="patientAppointmentLandingpageID"
          onClick={() => this.props.history.push("/patient-appointment-landingpage")}
        >
       Appointments
        </Text>
      
        <img className="rightArrowIcon"  style={{marginRight:"12px"}} src={RightOutlined} alt=""/>
      </div>

      <div>

      { 
        this.state.appointmentNavigationList?.map((navigation: any, index: number) => (
          <>
            {index === this.state.appointmentNavigationList.length - 1 ? (
              <>
                <Text
                  style={{ color: "#353535" }}
                  className="navigation-list-css"
                >
                  {navigation.name}
                </Text>
              </>
            ) : (
              <>
                <Text
                  style={{ color: "#498ECC" }}
                  className="navigation-list-css"
                  onClick={() =>
                    this.props.history.push({
                      pathname: navigation.route,
                      state: {
                        navigateList: this.state.appointmentNavigationList[0].name,
                        
                      }
                    })
                  }
                >
                  {navigation.name}
                </Text>
                <img className="rightArrowIcon" style={{marginRight:"12px"}}  src={RightOutlined} alt="" />
              </>
            )}
          </>
        ))}
      </div>
    </div>
  </Box>
  )
}

renderClinicDetails=(clinicDetails:any)=>{
  const {Title,Link} = Typography
  return (
    clinicDetails && clinicDetails.length>0 && (
      <Box mt={6} style={{maxHeight:"450px",overflow:"auto",overflowX:"hidden"}}  > 
      {
        clinicDetails.map((clinicDetail:any)=>(
          <>
          <Row gutter={[24,24]} key={clinicDetail.id} style={{height:"250px"}} align="middle" >
             <Col xl={14} sm={12}>
               <Title level={4} style={{textTransform:"capitalize"}} >
                 {clinicDetail?.attributes?.name}
               </Title>
              <div style={{display:"flex",flexDirection:"column",gap:"12px"}} >
              <ClinicDataText>
              Address:   {clinicDetail?.attributes?.address}
              </ClinicDataText>
              <ClinicDataText>
              Phone:   {clinicDetail.attributes?.contact_no}
              </ClinicDataText>
                 
               <ClinicDataText>
               Link: <Link href="#" >
                     {clinicDetail?.attributes?.link}
                     </Link>
              </ClinicDataText>
              <ClinicDataText>Hours: Monday to friday 9AM - 9PM </ClinicDataText>
  
              </div>
  
             </Col>
          <Col xl={10} sm={12} >
               <CustomCarousel
                 autoPlay={true}
                 showArrows={false}
                 showStatus={false}
                 infiniteLoop={true} >
                 {
                   clinicDetail?.attributes?.clinic_images?.map((clinicImage: any) => (
                    <ClinicImageBox >
                    <ClinicImage src={config + clinicImage.url} />
                </ClinicImageBox>
  
                   ))
                 }
               </CustomCarousel>
          </Col>
         </Row>
         <Divider/>
        
        </>
        ))
      }
      </Box>  
    )
  )
}

  renderDoctorProfile = () => {
    return (
      <Row gutter={[24, 8]} className="doctor_profile_container">
        <Col xs={6} sm={6} lg={3} xl={3} style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
          <img className="doc_profile_pic" src={this.state.image ? config + this.state.image : Default} alt="immg" />
        </Col>

        <Col xs={18} sm={12} lg={6} xl={6} >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
            <Typography className="doctorName">{this.state.full_name} </Typography>
            <Rate allowHalf disabled value={this.state.rating_average} />
          </div>
          <Typography className="doctorDescription" style={{ margin: "8px 0" }} >{this.state.about?.slice(0,100)}  </Typography>
          <Typography className="doctorDescription" style={{ margin: "8px 0" }} >{this.state.doctorDetails?.attributes?.doctor_category}  </Typography>
          <Typography className="doctor_specialisation" style={{ marginBottom: "8px" }} >{this.state.qualification} </Typography>
          <div className="doctorExperienceWrapper">
            {this.renderDoctorExperiencee(this.state)}
            <Typography className="doc_review" >{this.state.reviews_count} {this.state.reviews_count > 1 ? "reviews" : "review"}</Typography>
          </div>
        </Col>
        <Col xs={24} sm={12} lg={12} xl={12} >
          <div style={{ display: "flex", justifyContent: "right" }} >
            <Typography className="viewClinicDetails" data-test-id="viewClinic-detailsID" onClick={() => this.handleViewClinicDetails()}>{this.state.showClinicDetails? "Hide":"View"} Clinic Details </Typography>
          </div>
        </Col>
      </Row>
    )
  }

  renderConsultationTabs=()=>{
    return(
      <Box mt={2} mb={3} >
        <ToggleButtonGroup
          data-test-id="toggleButtonGroupId"
          value={this.state.activeTab}
          exclusive
          onChange={this.handleAlignment}
        >
          <ToggleButton value="Virtual" className={this.state.activeTab === "Virtual" ? "toggleInactive2" : ""}  >
            Online Consultation
          </ToggleButton>

          <ToggleButton value="In - Clinic" className={this.state.activeTab === "In - Clinic" ? "toggleInactive1" : ""}  >
            In Clinic Consultation
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    )
  }

  consultationShow=()=>{
    return(
      <Row justify="center" align="stretch" style={{ padding: "24px 0" }} >
        <Col xs={24}  >
          {this.renderConsultationTabs()}
          {
            this.state.activeTab === "Virtual" && this.state.doctorDetails && this.state.doctorDetails.attributes?.online_consultation.length > 0 ? this.renderOnlineConsultantComponent() : ""
          }
          {
            this.state.activeTab != "Virtual" && this.state.doctorDetails && this.state.doctorDetails.attributes?.inclinic_consultation.length > 0 ? this.renderinClinicConsultantComponent() : ""
          }
        </Col>

      </Row>
    )
  }
  // Customizable Area End

  render() {
    return (
      <>
      
      <NavbarCommonComponent data-test-id="navbarCommonComponentID3"  handleProfile={this.showDetailsHandler} />
      <PatientProfilePopup data-test-id="handleLogoutId3" profileData={this.state.showPopup} logoutFunctiuon={this.handleLogout} />
      {this.renderNavigationTitle()}

       {this.renderBreadCrumbs()}
      <div style={{height:"32px"}} />
      <div className="doctorfileWrapper" ref={this.myRef}  >
      {this.renderDoctorProfile()}
      {
        this.state.showClinicDetails && (this.renderClinicDetails(this.state.clinicDetails))
      }

      {/* <Row justify="center" align="stretch" style={{padding:"24px 0"}} >
        <Col xs={24}  >
            {this.renderConsultationTabs()}
            {
             this.state.activeTab ==="Virtual" && this.state.doctorDetails && this.state.doctorDetails.attributes?.online_consultation.length> 0 ? this.renderOnlineConsultantComponent() : ""
            }
            {
             this.state.activeTab !="Virtual" && this.state.doctorDetails && this.state.doctorDetails.attributes?.inclinic_consultation.length> 0 ? this.renderinClinicConsultantComponent() : ""
            }
        </Col>
        
      </Row> */}

      {this.consultationShow()}

        {
          this.state.online_consultation_choose ? (
            <Row gutter={[24,40]}>
            <Col xs={24} sm={24} lg={24} xl={24}>
              <div className="doctor_consultant_wrapper"  >
                <Typography className="doctor_consultant_title"> Select Date</Typography>
                <DatePicker
                  defaultValue={moment()} 
                  name="selectDate"
                  data-test-id="selectDateId" 
                  disabledDate={this.disabledPastDate} 
                  className="select_calender customized"
                  onChange={this.onDateChange}
                   />
              </div>
           
            </Col>
  
            <Col xs={24} sm={24} lg={24} xl={24}>
              <div className="doctor_consultant_wrapper" >
                <Typography className="doctor_consultant_title">Pick Time</Typography>
              </div>   
            </Col>

            <Col xs={12} sm={20} lg={20} >
              <Segmented data-test-id="handleTimeSlotId" onChange={(item)=>this.handleTimeSlotSection(item)}  size="large" options={this.options} />

            <div className="time_slot_container" >
            
               {
                  this.state.active_time_slot_section === "Morning" && this.state.onlineTimeSlot?.length ?  (
                    this.state.onlineTimeSlot.map((onlinemorningtimeSlot:any,index6:any)=>(
                       this.renderTimeSlotCard(onlinemorningtimeSlot,index6,"morningTimeSlotId")
                    ))

                  ) : null
                }


               {
                  this.state.active_time_slot_section === "Afternoon" && this.state.onlineTimeSlot?.length ?  (
                    this.state.onlineTimeSlot.map((onlineafternnontimeSlot:any,index:any)=>(
                      this.renderTimeSlotCard(onlineafternnontimeSlot,index,"afternoonTimeSlotId")
                    ))
                  ) :null
                }


               {
                  this.state.active_time_slot_section === "Evening" && this.state.onlineTimeSlot?.length ? (
                    this.state.onlineTimeSlot.map((onlineEveningtimeSlot:any,index8:any)=>(
                      this.renderTimeSlotCard(onlineEveningtimeSlot,index8,"eveningTimeSlotId")
                    ))
                  ):null
                }
            </div>
            </Col>

          </Row>

          ):null
        }

        <Row gutter={[24,24]} >
          <Col xs={24} >
          <Typography className="moreAboutDoctor_consultant_heading" >More About Dr. {this.state.full_name.split(" ")?.[0] } </Typography>
          </Col>
          <Col xs={24} sm={12} xl={8}>
            <div>
              <div className="language_container" >
                <img src={language} alt="" className="language_icons" />
                <div>
                  <Typography className="moreAbout_title" >Language Known</Typography>
                  <br/>
                  <div className="language-know-container" >
                    {
                                            <Typography className="consultant_language" >
                                            { this.state.doctorLanguage && this.handleDoctorKnownLanguage(this.state.doctorLanguage)?.join(", ") || "English" }
                                         </Typography>
                    }
                  </div>
                </div>
               
              </div>

            </div>
         
          </Col>
          <Col xs={24} sm={12} xl={8}>
          <div>

          <div className="language_container" >
                <img src={health} alt="" className="service_offer_icons" />
                <div>
                  <Typography className="moreAbout_title" >Services offered</Typography>
                  <br/>
                  <Typography className="consultant_language" >
                  Diagnosis and Treatment, Information 
                  and advice Health and nutrition advice, 
                  Prescription of medicine 
                 </Typography>

                </div>
               
              </div>
            </div>
            
          </Col>
          <Col xs={24} sm={24} xl={8}>
         
         <div style={{display:"flex",justifyContent:"center",alignItems:"center"}} >
         <Button 
           data-test-id="continueButtonId"
           className="continueButton" 
           onClick={()=>this.habdleContinueButton()}
           disabled={!this.state?.time_slot}
          >Continue</Button>
         </div>
          </Col>
        </Row>

        <CertificationComponent/>
      </div>
 <FooterCommonComponent />
 <Loader height={this.state.contentHeight} loading={this.state.isLoading} />

      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  appointmentContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  tableBox: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#ccc",
    padding: 15,
    marginVertical: 10,
  },
};

const ClinicImageBox = styled(Box)({

});

const ClinicDataText = styled(Box)({
  fontSize: "14px",
  fontWeight: 400,
  color: "#000"
});

const ClinicImage = styled("img")({
    height: "200px",
    width: "300px",
    borderRadius: "10px"
});

const CustomCarousel = styled(Carousel)({
  height: "200px",
  width: "100%",
  "@media (max-width:730px)": {
      display: "block",
      margin: "0 auto"
  }
});
// Customizable Area End