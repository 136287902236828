import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  Grid,
  FormControlLabel,
  Checkbox
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
import { MdModeEditOutline, MdOutlineContentCopy, MdEdit } from "react-icons/md";
import { BsPatchCheckFill, BsFillClockFill } from "react-icons/bs";
import { GrAddCircle } from "react-icons/gr";
import { AiOutlinePlus, AiOutlineDelete } from "react-icons/ai";
let config = require("../../../framework/src/config.js").baseURL;
import { Link } from 'react-router-dom';
import { Select, Form } from "antd";
import { TimeSlotType } from "./Customisableuserprofiles2Controller";
import { FaUserCircle } from 'react-icons/fa';
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import Customisableuserprofiles2Controller, {
  Props,
  configJSON,
} from "./Customisableuserprofiles2Controller";

export default class Customisableuserprofiles2 extends Customisableuserprofiles2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNavigatePage = () => {
    const is_kyc = localStorage.getItem("is_kyc");
    return (
      <>
        {this.state.isVerify === "approved" ?
          <Box sx={webStyle.pageHeader}>
            <Link to={is_kyc === "approved" ? "/doctorhome" : "/doctorprofiledetails"}><PageHeader>Home</PageHeader></Link>
            <Link to={is_kyc === "approved" ? "/doctorschedule" : "/doctorprofiledetails"}><PageHeader>Schedule</PageHeader></Link>
            <Link to={is_kyc === "approved" ? "/addpatient" : "/doctorprofiledetails"}><PageHeader>Add Patient</PageHeader></Link>
            <PageHeader>Chats</PageHeader>
          </Box>
          :
          <Box sx={webStyle.headerHide} onClick={this.handleVerifyKyc} data-test-id="matchId">
            <PageHeader data-test-id="homeId">Home</PageHeader>
            <PageHeader>Schedule</PageHeader>
            <PageHeader>Add Patient</PageHeader>
            <PageHeader>Chats</PageHeader>
          </Box>
        }
      </>
    );
  };

  renderClinicDetailsContainer = () => {
    switch (true) {
      case this.state.editClinicDetails:
        return this.renderEditCreateClinic();
      case this.state.showCreateClinic:
        return this.renderCreateClinic();
      default:
        return this.renderShowClinic();
    }
  }

  renderExperience = () => {
    return (
      <>
      {
        this.state.doctorProfileData?.data?.attributes?.experience === "1" ?
        `${this.state.doctorProfileData?.data?.attributes?.experience} year of experience`
        :
        `${this.state.doctorProfileData?.data?.attributes?.experience} years of experience`
      }
      </>
    )
  }

  renderProfileDetailsContainer = () => {
    const is_kyc = this.state.isVerify
    const doc_profile_pic = localStorage.getItem("profile_pic") || "";

    return (
      <>
        <Typography variant="h6">{configJSON.profileTitleText}</Typography>
        <Box sx={webStyle.doctorDetailsBoxStyle}>
          <Box sx={webStyle.doctorImgStyle}>
            {doc_profile_pic ?
              <img src={doc_profile_pic.includes("https") ? doc_profile_pic : config + this.state.userProfilePicture} style={{ width: "70px", height: "70px", borderRadius: "50%" }} alt="" />
              :
              <p>
                <FaUserCircle style={{ width: "70px", height: "70px", borderRadius: "50%" }} />
              </p>
            }
            <DoctorNameText>
              Dr. {this.state.doctorProfileData.data.attributes.full_name}
              &nbsp;
              <MdEdit data-test-id="editPageShowButtonId" onClick={this.handleEditDotorProfileDetails} style={{ cursor: "pointer" }} />
            </DoctorNameText>
            <SpecializationText>{this.state.doctorProfileData.data.attributes.specialization}</SpecializationText>
            {
              is_kyc === "approved" && <VerificationText><BsPatchCheckFill style={{ color: "#4d96dd", fontSize: "26px" }} />&nbsp;{configJSON.verifiedText}</VerificationText>
            }
            {
              is_kyc === "approved" ? <p style={{ color: "#4d96dd", cursor: "pointer" }} onClick={this.handleViewDoc} data-test-id="viewdocId">VIEW DOCUMENTS</p> : this.renderShowDocument()
            }
          </Box>
          <Box sx={webStyle.doctorDetailsStyle}>
            <PersonalDetailsText>
              {configJSON.personalDetailsText}
            </PersonalDetailsText>
            <AboutHeadingText>
              About
            </AboutHeadingText>
            <AboutText>
              {this.state.doctorProfileData.data.attributes.about}
            </AboutText>
            <HorizontalLine />
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
            <AboutHeadingText>
              Email Address
            </AboutHeadingText>
            <AboutText>
              {this.state.doctorProfileData.data.attributes.email}
            </AboutText>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
            <AboutHeadingText>
              Experience
            </AboutHeadingText>
            <AboutText>
                  {this.state.doctorProfileData?.data?.attributes?.experience === null ? "" :
                    this.renderExperience()
                  }
            </AboutText>
            </div>
            </div>
            <HorizontalLine />
            <AboutHeadingText>
              Contact Details
            </AboutHeadingText>
            <AboutText>
              {this.state.doctorProfileData.data.attributes.full_phone_number}
            </AboutText>
            <HorizontalLine />
            <AboutHeadingText>
              Known languages
            </AboutHeadingText>
            <div style={{display: "flex", flexFlow: "wrap"}}>
                {
                  this.state.selectedValues.map((item: any,index: number) => {
                    return (
                      <AboutText>
                        {item} {(this.state.selectedValues?.length -1 == index) ? "" : ","}
                      </AboutText>
                    )
                  })
                }
                </div>
          </Box>
        </Box>
      </>
    )
  };

  renderShowDocument = () => {
    const isUpload = localStorage.getItem("is_Upload")
    return (
      <>
        {
          isUpload ? <p style={{ color: "#4d96dd", cursor: "pointer" }} onClick={this.handleViewDoc} data-test-id="uploadId">VIEW DOCUMENTS</p>
            :
            <p style={{ color: "#4d96dd", cursor: "pointer" }} onClick={ this.isProfileUpdate} data-test-id="uploadId">UPLOAD YOUR DOCUMENTS AND VERIFY</p>
        }
      </>
    )
  };

  renderDoctorAvailability = () => {
    const is_kyc = this.state.isVerify
    return (
      is_kyc === "approved" &&
      <CustomGrid container spacing={3} style={{ width: "100%", margin: "0px" }}>
        <Grid item xs={12} style={{ paddingBottom: "0px",paddingLeft: "0px" }}>
          <AvailabilityHeader style={{ padding: "0px" }}>
            <Typography style={{ fontSize: "18px" }} data-test-id="availabilityId">My Availability</Typography>
            {this.state.showCreateAvailability ? "" : <Icon onClick={this.handleCreateAvailability} data-test-id="showAvailabilityId"><AiOutlinePlus /></Icon>}
          </AvailabilityHeader>
        </Grid>
        {this.state.showCreateAvailability ?
          <Form style={{ width: "100%" }}> {this.renderCreateAvailabilityContainer()} </Form>
          :
          <Grid item xs={12} md={4}>
            <AvailabilityTimeContainer>
              {this.state.doctorInClinicAvailability?.map((item: any) => {
                return (
                  item.day_of_week?.map((menuitem: string) => {
                    return (
                      <AvailabilityTime>
                        <Icon><BsFillClockFill /></Icon>
                        <TimeContainer>
                          <Time>
                            <Typography>
                              09:00 AM - 10:00 PM
                            </Typography>
                            <Icon>
                              <MdModeEditOutline onClick={() => this.handleEditAvailability(item.id)} data-test-id="editAvailabilityId"/>
                            </Icon>
                          </Time>
                          <Typography>
                            {menuitem} / {item.mode_type}
                          </Typography>
                        </TimeContainer>
                      </AvailabilityTime>
                    )
                  })
                )
              }
              )}

            </AvailabilityTimeContainer>
          </Grid>}
      </CustomGrid>
    )
  };

  renderClinicDetails = () => {
    const is_kyc = this.state.isVerify
    return (
      <>
        {is_kyc === "approved" &&
          <>
            <StyledClinicContainer >
              <StyledClinicSideBar>
                <ClinicHeader>
                  <Typography variant="h6">Clinic Details</Typography>
                  {!this.state.showCreateClinic &&
                    <StyledIcons>
                      <Icon data-test-id="showClinicFormId" onClick={this.handleCreateClinic}>
                        <AiOutlinePlus />
                      </Icon>
                      <Icon onClick={this.handleEditClinic} data-test-id="editClinicDetailsButtonId">
                        {this.state.getClinicName && <MdModeEditOutline />}
                      </Icon>
                    </StyledIcons>
                  }
                </ClinicHeader>
                {this.renderClinicDetailsContainer()}
              </StyledClinicSideBar>
              <StyledClinicImagesContainer>
                <StyledClinicImagesGrid container item spacing={3}>
                  <Grid item xs={12}>
                    <StyledClinicImageHeader>
                      <Title>Clinic Images</Title>
                      <StyledIcons>
                        <DeleteIcon>
                          {this.state.getImageId ? <AiOutlineDelete onClick={this.deleteImagefunction} data-test-id="deleteIconTestId" /> : ""}
                        </DeleteIcon>
                        <Icon>
                          <MdModeEditOutline data-test-id="editDoctorClinicImageId" onClick={this.handleDeleteImage} />
                        </Icon>
                      </StyledIcons>
                    </StyledClinicImageHeader>
                  </Grid>
                  {
                    this.state.showCreateClinic ?
                      <>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <AddClinicImg>
                            <input
                              data-test-id='imageSeletTestId'
                              multiple
                              type="file"
                              accept="image/*"
                              name="image-upload"
                              id="input"
                              onChange={this.imgFilehandler}
                            />
                            <label htmlFor='input' >
                              <GrAddCircle />
                            </label>
                          </AddClinicImg>
                        </Grid>
                        {
                          this.state.clinicImage.map((item) => {
                            return (
                              <Grid item xs={12} sm={6} md={4} lg={3}>
                                <ClinicImg src={item} />
                              </Grid>
                            )
                          })
                        }
                      </>
                      :
                      <>
                        {
                          this.state.editClinicDetails ? this.renderEditImage() :
                            this.state.getClinicImages?.map((item) => {
                              return (
                                <Grid item xs={12} sm={6} md={4} lg={3} style={{ position: "relative" }}>
                                  <ClinicImg src={config + item.url} alt="" />
                                  {this.state.deleteClinicImage ? <CheckboxTag data-test-id="deleteCheckboxId" onChange={(event) => this.handleCheckBoxClick(item, event)} /> : ""}
                                </Grid>
                              )
                            })
                        }
                      </>
                  }
                </StyledClinicImagesGrid>
              </StyledClinicImagesContainer>
            </StyledClinicContainer>
            {this.renderButton()}
          </>
        }
      </>
    )
  };

  renderButton = () => {
    return (
      <>
        {
          this.state.showCreateClinic &&
          <Grid container style={{ marginTop: "20px", marginBottom: "20px" }}>
            <ButtonContainer item lg={4}>
              <ButtonTag onClick={this.handleCloseCreateClinic} data-test-id="clinicCancelbutton">Cancel</ButtonTag>
              <ButtonTagColor onClick={this.postCreateClinicDestails} data-test-id="clinicSavebuttonId">Save</ButtonTagColor>
            </ButtonContainer>
          </Grid>
        }
      </>
    )
  }

  renderCreateClinic = () => {
    return (
      <>
        <ClinicDetail>
          <Title>
            Clinic Name
          </Title>
          <InputFieldType data-test-id="clinicNameId" placeholder="Type clinic name" onChange={this.handleClinicName} value={this.state.clinicName} />
        </ClinicDetail>
        {this.state.clinicNameErr && <ErrorTag>{this.state.clinicNameErr}</ErrorTag>}
        <ClinicDetail>
          <Title>
            Clinic Address
          </Title>
          <InputFieldType data-test-id="clinicAddressId" placeholder="Type clinic address" onChange={this.handleClinicAddress} value={this.state.clinicAddress} />
        </ClinicDetail>
        {this.state.clinicAddressErr && <ErrorTag>{this.state.clinicAddressErr}</ErrorTag>}
        <ClinicDetail>
          <Title>
            Contact Details
          </Title>
          <InputFieldType data-test-id="clinicContactId" placeholder="0000000000" onChange={this.handleClinicContactDetails} value={this.state.clinicContact} />
        </ClinicDetail>
        {this.state.clinicContactErr && <ErrorTag>{this.state.clinicContactErr}</ErrorTag>}
        <ClinicLink>
          <TitleContainer>
            <Title>
              Clinic Link
            </Title>
            <Icon>
            {this.state.clinicLink ? <MdOutlineContentCopy onClick={this.handleNewCopyLink}/> :  <MdOutlineContentCopy style={{cursor: "none"}}/>}
            </Icon>
          </TitleContainer>
          <InputFieldType data-test-id="clinicLinkId" placeholder="www.xyz.com" onChange={this.handleClinicLink} value={this.state.clinicLink} id="newCopyLinkId"/>
        </ClinicLink>
        {this.state.clinicLinkErr && <ErrorTag>{this.state.clinicLinkErr}</ErrorTag>}
      </>
    )
  }

  renderCreateAvailability = () => {
    return (
            <Form.Item
        label=""
        name="followDays"
        rules={[
          {
            required: true,
            message: "Please select week days"
          }
        ]}
      >
        <Select
          data-test-id="selectWeekDaysID"
          style={{ width: "100%" }}
          showArrow={true}
          showSearch={false}
          optionFilterProp="children"
          placeholder="Select Follow Up Days"
          onChange={this.handleSelectDays}
        >
          {this.state.weekDays.map((item: string) => {
            return (
              <Select.Option data-test-id="selectWeekDaysValueID" placeholder="Select days" value={item} name="followDays">
                {item}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
          )
  };

  renderModeSelect = () => {
    return (
            <Form.Item
        label=""
        name="modeSelect"
        rules={[
          {
            required: true,
            message: "Please select mode type"
          }
        ]}
      >
        <SelectTag
          data-test-id="selectModeID"
          style={{ width: "100%" }}
          placeholder="Select Mode"
          onChange={this.handleSelectModeType}
        >
          {this.state.modeSelectData.map((item: string) => (
            <Select.Option data-test-id="selectModeValueId" value={item} name="modeSelect">
              {item}
            </Select.Option>
          ))}
        </SelectTag>
      </Form.Item>
          )
  };

  renderFollowUpTime = () => {
    return (
      <Form.Item
        label=""
        name="followUp"
        style={{ margin: "0px" }}
        rules={[
          {
            required: true,
            message: "Please select time zone"
          }
        ]}
      >
        <SelectTag
          data-test-id="selectTimeSlotId"
          style={{ width: "100%" }}
          onSelect={this.handleSelectFollowUpTime}
          placeholder="Select Follow Up Time"
        >
          {this.state.followUpTimeSlots.map((item: string) => (
            <Select.Option data-test-id="selectTimeSlotValueId" value={item} name="followUp">
              {item}
            </Select.Option>
          ))}
        </SelectTag>
      </Form.Item>
    )
  };

  renderCreateAvailabilityContainer = () => {
    return (
      <>
        <Grid container spacing={4}>
          <Grid item lg={3} xl={3} md={4} sm={6} xs={12}>
            <TitleText>Mode :</TitleText>
            {this.renderModeSelect()}
          </Grid>
          <Grid item lg={4} xl={4} md={4} sm={6} xs={12}>
            <TitleText>Follow Up Days :</TitleText>
            {this.renderCreateAvailability()}
          </Grid>
          <Grid item lg={5} xl={4} md={4} sm={6} xs={12}>
            <TitleText>Follow Up Time :</TitleText>
            <div ref={this.followUpTimeRef}>
              {this.renderFollowUpTime()}
              {this.state.timeZone.time == "30 Minutes" && this.renderCheckbox(this.state.followUpTimeHalf)}
              {this.state.timeZone.time == "60 Minutes" && this.renderCheckbox(this.state.followUpTimeHr)}
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ margin: "20px 0px 20px 0px" }}>
          <ButtonContainer item lg={4}>
            <ButtonTag data-test-id="closeAvailabilityId" onClick={this.handleCloseCreateAvailability}>Cancel</ButtonTag>
            <ButtonTagColor data-test-id="saveButtonId" onClick={this.postDoctorCreateAvailability}>Save</ButtonTagColor>
          </ButtonContainer>
        </Grid>
      </>
    )
  };

  renderShowClinic = () => {
    return (
      <>
        <ClinicDetail>
          <Title>
            Clinic Name
          </Title>
          <SubTitle data-test-id="clinicNameId">
            {this.state.showClinicData.data.attributes.name}
          </SubTitle>
        </ClinicDetail>
        <ClinicDetail>
          <Title>
            Clinic Address
          </Title>
          <SubTitle>
            {this.state.showClinicData.data.attributes.address}
          </SubTitle>
        </ClinicDetail>
        <ClinicDetail>
          <Title>
            Contact Details
          </Title>
          <SubTitle>
            {this.state.showClinicData.data.attributes.contact_no}
          </SubTitle>
        </ClinicDetail>
        <ClinicLink>
          <TitleContainer>
            <Title>
              Clinic Link
            </Title>
            {
              this.state.getClinicName &&
            <Icon>
              <MdOutlineContentCopy onClick={this.handleClinicCopyLink} data-test-id="clinicCopyId"/>
            </Icon>
            }
          </TitleContainer>
          <SubTitle id="copyLinkId">
            {this.state.showClinicData.data.attributes.link}
          </SubTitle>
        </ClinicLink>
      </>
    )
  };

  renderCheckbox = (data: Array<TimeSlotType>) => {
    return (
      <>
        {this.state.showHalfFollowUpTime ?
          <FollowUpTimeBox>
            <Box>
              {data.slice(0, Math.floor(data.length / 2)).map((item: TimeSlotType) => {
                return (
                  <FormControlLabel
                    data-test-id="formControlId"
                    control={<Checkbox name={item.timeSlot} checked={item.isSelect} onChange={this.handleTimeSlots} data-test-id="timeSlotsId" value={item.timeSlot} />}
                    label={item.timeSlot}
                  />
                )
              })}
            </Box>
            <Box>
              {data.slice(Math.floor(data.length / 2)).map((item: TimeSlotType) => {
                return (
                  <FormControlLabel
                    data-test-id="formControlId"
                    control={<Checkbox data-test-id="timeSlotsId" name={item.timeSlot} checked={item.isSelect} onChange={this.handleTimeSlots} value={item.timeSlot} />}
                    label={item.timeSlot}
                  />
                )
              })}
            </Box>
          </FollowUpTimeBox> : null}
      </>
    )
  };

  renderEditCreateClinic = () => {
    return (
      <>
        <ClinicDetail>
          <Title>
            Clinic Name
          </Title>
          <InputFieldType data-test-id="editClinicNameId" placeholder="Type clinic name" onChange={this.handleEditClinicName} value={this.state.getClinicName} />
        </ClinicDetail>
        {this.state.clinicNameErr && <ErrorTag>{this.state.clinicNameErr}</ErrorTag>}
        <ClinicDetail>
          <Title>
            Clinic Address
          </Title>
          <InputFieldType data-test-id="editClinicAddressId" placeholder="Type clinic address" onChange={this.handleEditClinicAddress} value={this.state.getClinicAddress} />
        </ClinicDetail>
        {this.state.clinicAddressErr && <ErrorTag>{this.state.clinicAddressErr}</ErrorTag>}
        <ClinicDetail>
          <Title>
            Contact Details
          </Title>
          <InputFieldType data-test-id="editClinicContactNoId" placeholder="0000000000" onChange={this.handleEditClinicContactDetails} value={this.state.getClinicContactNum} />
        </ClinicDetail>
        {this.state.clinicContactErr && <ErrorTag>{this.state.clinicContactErr}</ErrorTag>}
        <ClinicLink>
          <TitleContainer>
            <Title>
              Clinic Link
            </Title>
            <Icon>
              <MdOutlineContentCopy onClick={this.handleEditCopyLink}/>
            </Icon>
          </TitleContainer>
          <InputFieldType data-test-id="editClinicLinkId" placeholder="www.xyz.com" onChange={this.handleEditClinicLink} value={this.state.getClinicLink} id="EditCopyLinkId"/>
        </ClinicLink>
        {this.state.clinicLinkErr && <ErrorTag>{this.state.clinicLinkErr}</ErrorTag>}
        <ButtonContainer item lg={4} style={{ margin: "20px 0px" }}>
          <ButtonTag onClick={this.handleCloseEditClinic} data-test-id="editClinicCancelbutton">Cancel</ButtonTag>
          <ButtonTagColor onClick={this.editClinicDetails} data-test-id="saveClinicDetailsButtonId">Save</ButtonTagColor>
        </ButtonContainer>
      </>
    )
  };

  renderEditImage = () => {
    return (
      <StyledClinicImagesGrid container item spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AddClinicImg>
            <input
              data-test-id="imgUploadId"
              multiple
              type="file"
              accept="image/*"
              name="upload"
              id="inputupload"
              onChange={this.editImgFilehandler}
            />
            <label htmlFor="inputupload">
              <GrAddCircle />
            </label>
          </AddClinicImg>
        </Grid>
        {
          this.state.editClinicImage?.map((item) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ClinicImg src={item} />
              </Grid>
            )
          })
        }
        {
          this.state.getClinicImages?.map((item) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ClinicImg src={config + item.url} alt="" />
              </Grid>
            )
          })
        }
      </StyledClinicImagesGrid>
    )
  };

  renderEditAvailabilityContainer = () => {
    return (
      <>
      <Typography style={{ fontSize: "18px",marginTop: "42px" }} data-test-id="myId">My Availability</Typography>
        <Grid container spacing={4}>
          <Grid item lg={3} xl={3} md={4} sm={6} xs={12}>
            <TitleText>Mode :</TitleText>
            {this.renderEditModeSelect()}
          </Grid>
          <Grid item lg={4} xl={4} md={4} sm={6} xs={12}>
            <TitleText>Follow Up Days :</TitleText>
            {this.renderEditAvailabilityDays()}
          </Grid>
          <Grid item lg={5} xl={4} md={4} sm={6} xs={12}>
            <TitleText>Follow Up Time :</TitleText>
            <div ref={this.followUpTimeRef}>
              {this.renderEditFollowUpTime()}
              {this.state.editTimeZone.time == "30 Minutes" && this.renderEditCheckbox(this.state.editFollowUpTimeHalf)}
              {this.state.editTimeZone.time == "60 Minutes" && this.renderEditCheckbox(this.state.editFollowUpTimeHr)}
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ margin: "20px 0px 20px 0px" }}>
          <ButtonContainer item lg={4}>
            <ButtonTag data-test-id="closeEditAvailabilityId" onClick={this.handleCloseEditAvailability}>Cancel</ButtonTag>
            <ButtonTagColor data-test-id="saveEditButtonId" onClick={this.putDoctorEditAvailability}>Save</ButtonTagColor>
          </ButtonContainer>
        </Grid>
      </>
    )
  };

  renderEditModeSelect = () => {
    return (
      <Form
        fields={[
          {
            name: ["editmodeSelect"],
            value: this.state.editModeValue,
          }
        ]}
      >
        <Form.Item
          label=""
          name="editmodeSelect"
          rules={[
            {
              required: true,
              message: "Please select mode type"
            }
          ]}
        >
          <SelectTag
            data-test-id="editSelectModeID"
            style={{ width: "100%" }}
            placeholder="Select Mode"
            onChange={this.editHandleSelectModeType}
          >
            {this.state.modeSelectData.map((item: string) => (
              <Select.Option data-test-id="selectModeValueId" value={item} name="editmodeSelect">
                {item}
              </Select.Option>
            ))}
          </SelectTag>
        </Form.Item>
      </Form>
    )
  };

  renderEditAvailabilityDays = () => {
    return (
      <Form
        fields={[
          {
            name: ["editfollowDays"],
            value: this.state.editWeekDaysValue,
          }
        ]}
      >
        <Form.Item
          label=""
          name="editfollowDays"
          rules={[
            {
              required: true,
              message: "Please select week days"
            }
          ]}
        >
          <Select
            data-test-id="selectEditWeekDaysID"
            style={{ width: "100%" }}
            showArrow={true}
            showSearch={false}
            optionFilterProp="children"
            placeholder="Select Follow Up Days"
            onChange={this.editHandleSelectDays}
          >
            {this.state.weekDays.map((item: string) => {
              return (
                <Select.Option data-test-id="selectWeekDaysValueID" placeholder="Select days" value={item} name="editfollowDays">
                  {item}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
      </Form>
    )
  };

  renderEditFollowUpTime = () => {
    return (
      <Form
        fields={[
          {
            name: ["editFollowUp"],
            value: this.state.editTimeZone.time,
          }
        ]}
      >
        <Form.Item
          label=""
          name="editFollowUp"
          style={{ margin: "0px" }}
          rules={[
            {
              required: true,
              message: "Please select time zone"
            }
          ]}
        >
          <SelectTag
            data-test-id="selectEditTimeSlotId"
            style={{ width: "100%" }}
            onSelect={this.handleEditSelectFollowUpTime}
            placeholder="Select Follow Up Time"
          >
            {this.state.followUpTimeSlots.map((item: string) => (
              <Select.Option data-test-id="selectTimeSlotValueId" value={item} name="editFollowUp">
                {item}
              </Select.Option>
            ))}
          </SelectTag>
        </Form.Item>
      </Form>
    )
  };

  renderEditCheckbox = (data: Array<TimeSlotType>) => {
    return (
      <>
        {this.state.showEditHalfFollowUpTime ?
          <FollowUpTimeBox>
            <Box>
              {data.slice(0, Math.floor(data.length / 2)).map((item: TimeSlotType) => {
                return (
                  <FormControlLabel
                    data-test-id="editformControlId"
                    control={<Checkbox name={item.timeSlot} checked={item.isSelect} onChange={this.editHandleTimeSlots} data-test-id="edittimeSlotsId" value={item.timeSlot} />}
                    label={item.timeSlot}
                  />
                )
              })}
            </Box>
            <Box>
              {data.slice(Math.floor(data.length / 2)).map((item: TimeSlotType) => {
                return (
                  <FormControlLabel
                    data-test-id="editformControlId"
                    control={<Checkbox data-test-id="edittimeSlotsId" name={item.timeSlot} checked={item.isSelect} onChange={this.editHandleTimeSlots} value={item.timeSlot} />}
                    label={item.timeSlot}
                  />
                )
              })}
            </Box>
          </FollowUpTimeBox> : null}
      </>
    )
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <ThemeProvider theme={theme}>
        <NavbarCommonComponent data-test-id="showProfileHeaderId" handleProfile={this.handelDoctorProfilePopupShowFunction} />
        <DoctorProfilePopup data-test-id="handleDoctorLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleDoctorLogout} showProfile={this.handleDoctorProfile} doctorDetailsData={this.state.doctorProfileData} handleEditProfile={this.handleEditDoctor} handleClosePopup={this.handlePopupClose} />
        {this.renderNavigatePage()}
        <Box sx={webStyle.profileContainerStyle}>
          {this.renderProfileDetailsContainer()}
          {
            !this.state.isEdit ? this.renderDoctorAvailability() : this.renderEditAvailabilityContainer()
          }
          {this.renderClinicDetails()}
        </Box>
        <FooterDoctor />
      </ThemeProvider>
      <Loader height={this.state.contentHeight} loading={this.state.isLoader} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  profileContainerStyle: {
    width: "89%",
    margin: "0 auto"
  },
  doctorDetailsBoxStyle: {
    width: "100%",
    backgroundColor: "rgba(77, 150, 221, 0.09)",
    marginBottom: "30px",
    display: "flex",
    flexFlow: "wrap"
  },
  doctorImgStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "50%",
    height: "100% auto",
    "@media (max-width: 900px)": {
      width: "100%",
      marginBottom: "20px",
      marginTop: "20px"
    },
  },
  doctorDetailsStyle: {
    width: "40%",
    height: "100%",
    paddingTop: "22px",
    paddingBottom: "22px",
    "@media (max-width: 900px)": {
      width: "85%",
      margin: "0 auto"
    },
  },
  pageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
    width: "89%",
    margin: "0 auto"
  },
  headerHide: {
    justifyContent: "space-between",
    flexFlow: "wrap",
    width: "89%",
    display: "flex",
    alignItems: "center",
    margin: "0 auto"
  }
};

const SpecializationText = styled("p")({
  color: "grey",
  marginBottom: "0px",
  fontSize: "14px"
});

const VerificationText = styled("p")({
  color: "black",
  marginBottom: "0px",
  fontSize: "15px",
  display: "flex",
  alignItems: "center",
});

const DoctorNameText = styled("p")({
  color: "#4d96dd",
  marginBottom: "0",
  textAlign: "center"
});

const PersonalDetailsText = styled('h5')({
  fontSize: "18px",
});

const AboutHeadingText = styled('p')({
  fontSize: "16px",
  color: "#808080",
  marginBottom: "0"
});

const AboutText = styled('p')({
  fontSize: "16px",
  marginBottom: "0"
});

const HorizontalLine = styled('hr')({
  height: "2px",
  backgroundColor: "#808080",
  margin: "13px 0 13px 0"
});

const CustomGrid = styled(Grid)({
  width: '100%',
  margin: '0px'
});

const ClinicHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '18px'
});

const AvailabilityHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '18px',
  width: "400px",
  "@media(max-width:940px)": {
    width: "100%"
  }
});

const Title = styled(Typography)({
  color: 'gray'
});

const TitleContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '18px',
  paddingTop: '1rem',
});

const ClinicDetail = styled(Box)({
  padding: '1rem 0 1rem 1rem',
  borderBottom: '1px solid lightgray',
});

const Icon = styled(Box)({
  color: '#4d96dd',
  fontSize: '1.5rem',
  cursor: 'pointer'
});

const ColoredText = styled(Typography)({
  color: '#4d96dd',
});

const AvailabilityTime = styled(Box)({
  display: 'flex',
  gap: '1rem'
});

const TimeContainer = styled(Box)({
  width: '100%',
  height: "70px"
});

const Time = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flex: 1
});

const SubTitle = styled(Typography)({
  color: "black"
});

const PageHeader = styled("p")({
  color: "black",
  fontSize: "16px",
  fontWeight: 600
});

const DeleteIcon = styled(Box)({
  fontSize: '1.5rem',
  cursor: 'pointer'
});

const AddClinicImg = styled(Box)({
  backgroundColor: "rgba(77, 150, 221, 0.09)",
  width: '100%',
  height: "130px",
  borderRadius: '0.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.5rem',
  cursor: "pointer",
  "@media(max-width:600px)": {
    height: "180px"
  }
});

const AvailabilityTimeContainer = styled(Box)({
  borderRadius: '0.5rem',
  border: '1px solid lightgray',
  padding: '8px',
  marginTop: '16px',
  height: "140px",
  overflowY: "scroll"
});

const ClinicLink = styled("div")({
  paddingLeft: '16px'
}
);

const ClinicImg = styled('img')({
  width: "100%",
  height: "125px",
  borderRadius: "8px",
  verticalAlign: 'middle',
  "@media(max-width:600px)": {
    height: "180px",
  }
});

const StyledClinicContainer = styled(Box)({
  display: "flex",
  gap: "20px",
  marginBottom: "20px",
  "@media(max-width: 960px)": {
    flexDirection: "column",
    padding: "0 20px"
  }
});

const StyledClinicSideBar = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flex: "0.3"
});

const StyledClinicImagesGrid = styled(Grid)({
  width: "100%",
  margin: "0px"
});

const StyledClinicImagesContainer = styled(Box)({
  marginTop: "46px",
  flex: "0.7"
});

const StyledClinicImageHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "170px"
});

const StyledIcons = styled(Box)({
  display: "flex",
  gap: "10px",
  alignItems: "center"
});

const TitleText = styled("p")({
  color: "black",
  marginTop: "8px",
  marginBottom: "8px"
});

const SelectTag = styled(Select)({
  marginBottom: "10px",
});

const ButtonTag = styled("button")({
  display: "flex",
  alignItems: "center",
  width: "120px",
  borderRadius: "20px",
  height: "35px",
  border: "1px solid #8080804a",
  backgroundColor: "white",
  justifyContent: "center",
  marginRight: "10px",
  color: "#535151"
});

const ButtonTagColor = styled("button")({
  display: "flex",
  alignItems: "center",
  width: "120px",
  borderRadius: "20px",
  height: "35px",
  backgroundColor: "#4d96dd",
  justifyContent: "center",
  marginRight: "10px",
  border: "0",
  color: "white"
});

const ButtonContainer = styled(Grid)({
  display: "flex",
  alignItems: "center"
});

const FollowUpTimeBox = styled("div")({
  display: "flex",
  overflowX: "scroll",
  height: "290px",
  zIndex: 1,
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
});

const InputFieldType = styled("input")({
  width: "100%",
  border: "0"
});

const ErrorTag = styled("p")({
  color: "red",
  fontSize: "14px",
  margin: "0 !important"
});

const CheckboxTag = styled(Checkbox)({
  position: "absolute",
  bottom: "20px",
  left: "20px",
  padding: "0px"
});
// Customizable Area End
