import React from "react";
// Customizable Area Start
import { Col, Row } from "antd";
import { time, location } from "../../splashscreen/src/assets";
import DoctorUpcomingAppointmentController from "./DoctorUpcomingAppointmentController.web";
import moment from "moment";
import { AiOutlineCalendar } from "react-icons/ai"
import { dummy } from "./assets";
let config = require('../../../framework/src/config.js').baseURL;
import { withRouter } from 'react-router-dom';
// Customizable Area End

 export class DoctorUpcomingAppointment extends DoctorUpcomingAppointmentController {

  // Customizable Area Start
  renderUpcomingList = () => {
    return (
      <div className={this.state.upCominAppointment.upcoming_appointments?.data.length > 0 ? "appointment-cards-container" : "container_upcoming"}>
        <div className="appointment-card-main">
          {this.state.upCominAppointment.upcoming_appointments?.data.map((item: any) => {
            return (
              <>
                <Row onClick = {() => this.handleAppointmentsDetails(item.id)} data-test-id="upComingId">
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <div className="appointment-card-left">
                      <div className="appointment-userImg-container">
                        {
                          item.attributes?.patient_profile_image ? <img src={config + item.attributes?.patient_profile_image} alt="" /> : <img src={dummy} alt="" />
                        }
                      </div>
                      <div className="appointment-card-left-details">
                        <div className="card-name-section">
                          <p className="appointment-user-name">{item.attributes.patient_full_name}</p>
                          <p className="vertical-line"></p>
                          <p className="appointment-user-age">
                            <span>
                              {item.attributes.patient_year}&nbsp;
                              {item.attributes.patient_gender === "Male" ? "M" : "F"}
                            </span>
                          </p>
                          <p className="user-tag">Follow up</p>
                        </div>
                        <div className="appointment-location-container">
                          <img
                            className="location-icon"
                            src={location}
                            alt=""
                          />
                          &nbsp;
                          <p className="location-name"> {item.attributes.mode_type} </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <div className="appointment-user-left-container">
                      <div className="appointment-user-time-date-container">
                        <div className="date-container">
                          <AiOutlineCalendar />
                          {/* <img src={calendar} alt="location-icon" /> */}
                          <p>
                            {moment(item.attributes.appointment_date).format("DD MMM YYYY")}
                          </p>
                        </div>
                        <div className="time-container">
                          <img src={time} alt="location-icon" />
                          <p>{item.attributes.time_slot}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <p className="horizontal-line"></p>
              </>
            )
          })}
        </div>
      </div>
    )
  }
  // Customizable Area End


  // Customizable Area Start
  render() {
    return (
      <>
        {this.renderUpcomingList()}
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withRouter(DoctorUpcomingAppointment);
// Customizable Area End
